//Angular Imports
import { Component, HostBinding, HostListener, TemplateRef, ViewChild } from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { v4 as uuidv4 } from "uuid";

//Internal Imports
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
  SharedService,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimeUtcPipe,
  AuthorizationService,
  UserProfileService,
} from "../../../../framework";
import { FormsListService } from "../../services";
import { ActivatedRoute } from "@angular/router";
import { CustomPinnedRowRenderer } from "../formsListComponent/formsList.component.pinnedCellRenderer";
import { RuleEngineForm } from "../../../../form-page/models/ruleEngineForm.model";
import { AdminForm, Rule } from "../../../../form-page";
import { Extension } from "../extension";
import { CPMElement, CPMForm, TransactionResponse } from "../../models";
import { Transactions } from "../transactions";
import { indexOf } from "lodash";
import { HttpErrorResponse } from "@angular/common/http";
import { SelectedFormsComponent } from "../selectedForms/selectedForms.component";
import { PolicyFormExport } from "../../../../policy-forms/models/policyFormExport.model";
import * as saveAs from "file-saver";
import { ProductList } from "../../models/productList.model";
import { FormControl, UntypedFormControl } from "@angular/forms";
import { BrokerInfo, ETaskRequest, InsuredInfo } from "../../models/etask.request.model";
import { MatDialog } from "@angular/material/dialog";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";


/**
 * Forms Component
 */
@Component({
  selector: "formsList",
  templateUrl: "./formsList.component.html",
  styleUrls: ["./formsList.component.scss"],
})
export class FormsListComponent extends ApplicationInsightsBaseComponent {
  //Public Variables
  /**
   * Host binding for width
   */
  public lastKeyCode: number;
  public secretKeyCode = false;

  @ViewChild('dialogContent') dialogContent: TemplateRef<any>;
  @HostBinding("style.width") width: string;
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.lastKeyCode = event.keyCode;
    if (event.keyCode == 17 && this.lastKeyCode == 17) {
      this.secretKeyCode = true;
    }
  }
  public formsGridOptions: GridOptions;
  public policyFormList: RuleEngineForm[] = [];
  public allAdminForms: AdminForm[] = [];
  public packageNames: string[] = [];
  public currentPolicyForm: string;
  public currentPackage: string;
  lineOfBusinessList: ProductList[] = [];
  authRepresentative = new FormControl("");
  private errorShown = false;
  isDropdownOpen = false;

  //Codes that indicate excess forms, only need excess for filter because if its not excess its primary, hardcoded because we cannot get this specific list of codes programatically
  excessCodes: any[] = ["118", "128", "130", "131", "298"];
  covTypeList: any[] = [
    { ID: "primary", Name: "Primary" },
    { ID: "excess", Name: "Excess" },
  ];

  //Lists for filtering and updating the grid based on selectors
  public selectedLOB: string;
  public selectedCovList: any[] = [];
  public selectedCovForms: any[] = [];
  public selectedCov: any;
  public packetFormList: RuleEngineForm[] = [];

  //No longer a need to write CPM elements manually, can get from stored FAST data

  public stateRuleList: any[] = [];

  public paperRuleList: any[] = [];
  public allPaperRules: any[] = [];
  public bermudaPaperOnly: any[] = [
    { Code: "15", Description: "Markel Bermuda Limited" },
  ];
  public evanstonPaperOnly: any[] = [
    { Code: "4", Description: "Evanston Insurance Company" }
  ];

  public decPageList: any[] = [];

  public selectedState: string;
  public selectedStateForms: any[] = [];
  public selectedPaper: string;
  public selectedPaperForms: any[] = [];
  public selectedCovType: string;
  public selectedCovTypeForms: any[] = [];
  public selectedDecPage: string;
  public selectedDecPageForms: any[] = [];
  public primaryForms: any[] = [];
  public excessForms: any[] = [];
  public isMandatory: string = "All";

  lobQuery: string;
  checkedForms: string[] = [];
  public filter: string = "default";
  public searchInput: string;
  public selectedInput: string = "formnum";
  checkedFormsFulls: RuleEngineForm[] = [];
  public isMandatoryList = ['All', 'Mandatory', 'Optional'];

  //For Issuance
  public isInternationalCasulty: boolean;
  public isMINT: boolean;
  public isETaskLOB: boolean = false;
  public isExpanded: boolean = false;
  public currentTransaction: TransactionResponse;
  public producerName: string = "";
  public insuredDBA: string = "";
  public insuredName: string = "";
  public insuredAddress: string = "";
  public insuredAddress2: string = "";
  public insuredAddress3: string = "";
  public policyNumber: string = "";
  public policyEffectiveDate = "";
  public policyExpirationDate = "";
  public isExistingTransaction: boolean = false;
  public selectedTransactionId: string = "";
  public authorizedRepName: string = "";
  public authorizedRepSurname: string = "";
  public authorizedRepTitle: string = "";
  public dealNumber: string = "";
  public lobIsCpmV2: boolean = true;
  public authRepPlaceholder = "Click to Select";

  public issuanceLOB;
  boolean = false;

  //Hard coding underwriter list, no db location for this at the moment
  public underwriters: any[] = [
    { Name: "Michael", Surname: "Souza", Title: "Vice President" },
    { Name: "Chris", Surname: "Finsness", Title: "Underwriter" },
    { Name: "Jillian", Surname: "Dupree", Title: "Senior Underwriter"}
  ];

  public mintUnderwriters: any[] = [
    { Name: "Kyle", Surname: "Rogers", Title: "Senior Underwriter" },
    { Name: "Sarah", Surname: "Shriner", Title: "Associate Underwriter" },
    { Name: "Andrew", Surname: "Umphress", Title: "Head of Terrorism - North America"}
  ]
  public selectedUnderwriter: string = "";

  public selectedFormCount = 0;

  readonly appInsights = new ApplicationInsights({
    config: {
      connectionString: window.sessionStorage.getItem("AIKey")
      /* ...Other Configuration Options... */
    }
  });

  initialRowDataLoad$;
  stateCtrl: UntypedFormControl;

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private route: ActivatedRoute,
    private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private formsService: FormsListService,
    private _modal: NgbModal,
    private sharedService: SharedService,
    private authService: AuthorizationService,
    private dateTimePipe: DateTimeUtcPipe,
    private userProfileService: UserProfileService,
    private dialog: MatDialog
  ) {
    super(_appInsightsService);
    this.width = "100%";
  }

  get FormsWritePermission(): boolean {
    return this.authService.FormsWrite();
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {

    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView({
      name: 'formsLibrary',
      uri: location.pathname + location.search
    });

    this.stateCtrl = new UntypedFormControl();

    //Get Cpm elements from database and store for later

    //Gets all forms from the db EXPERIMENTAL, not implemented due to long execution time
    /*this.formsService.getAllAdminForms().subscribe(res => {
      this.allAdminForms = res;
      console.log(this.allAdminForms);
    })*/
    this.isInternationalCasulty = this.authService.isIC();
    this.isMINT = this.authService.isMINT();

    this.sharedService.getAllCpmElementsRulesEngine().subscribe((el) => {
      let stateList = el.filter((a) => a.CPMDataElementName == "State")[0];
      this.stateRuleList = stateList.CPMFieldValues;
      let paperList = el.filter(
        (a) => a.CPMDataElementName == "Writing Company"
      )[0];
      this.allPaperRules = paperList.CPMFieldValues;
      let decList = el.filter((a) => a.CPMDataElementName == "ICDecPage")[0];
      this.decPageList = decList.CPMFieldValues;
    }, (err) => {
      console.log(err);
      this.showError();
    });

    this.configureGrid();
    //If user is an IC user and NOT an admin, automatically set LOB to IC and generate INTC coverage
    this.formsService.getProducts().subscribe((res) => {
      this.lineOfBusinessList = res;
      console.log(this.lineOfBusinessList);
      //For users who have both issuance roles do not default to anything
      if (this.isInternationalCasulty && this.isMINT) {
        console.log("Multiple Issuance Roles");
      }
      else if (this.isInternationalCasulty && !this.authService.Administrator()) {
        this.selectedLOB = "INTC";
        this.issuanceLOB = true;
        this.onLOBChange({ ID: "INTC" });
        this.selectedCovType = "Primary";
        this.getPolicyFormMasterList(this.selectedCov);
      }
      else if (this.isMINT && !this.authService.Administrator()) {
        this.selectedLOB = "MIN";
        this.issuanceLOB = true;
        this.onLOBChange({ ID: "MIN" });
        this.selectedCovType = "Primary";
        this.getPolicyFormMasterList(this.selectedCov);
      }
    }, (err) => {
      console.log(err);
      this.showError();
    });
  }

  showError() {
    if (!this.errorShown) {
      this.errorShown = true;
      setTimeout(() => {
        this.toastr.error(
          'There was a problem connecting to Document Generation/Document Attachment/Policy Issuance services, if this continues please submit a <a class="toastr-link" target="_blank" href="https://markel.service-now.com/nav_to.do?uri=%2Fcatalog_home.do%3Fsysparm_view%3Dcatalog_business_service_request_catalog" style="color: var(--color-orange)">service request.</a>',
         // 'We’ve detected one or more services are currently down. If you’re experiencing issues, please submit a <a class="toastr-link" target="_blank" href="https://markel.service-now.com/nav_to.do?uri=%2Fcatalog_home.do%3Fsysparm_view%3Dcatalog_business_service_request_catalog" style="color: var(--color-orange)">service request.</a>',
          "Attention",
          {
            enableHtml: true,
            closeButton: true,
            timeOut: 3000,
          }
        );
      }, 3000);
    }
  }

  /**
   * NgDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  //Public Methods

  showHideIC() {
    this.isExpanded = true;
    this.dialog.open(this.dialogContent);
    
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  // Populate Coverage filter list based on selected LOB, reset other filters
  onLOBChange(selectedvalue) {
    this.isETaskLOB = false;
    this.selectedCovType = "";
    this.selectedCovTypeForms = [];
    this.selectedState = "";
    this.selectedStateForms = [];
    this.selectedPaperForms = [];
    this.selectedCov = null;
    this.selectedPaper = "";
    this.selectedCovList = this.lineOfBusinessList.filter(
      (x) => x.ID == selectedvalue.ID
    )[0].Coverages;
    if (selectedvalue.ID == "BC") {
      this.lobIsCpmV2 = false;
      this.issuanceLOB = false;
    } else if (selectedvalue.ID == "ML") {
      this.lobIsCpmV2 = false;
      this.issuanceLOB = false;
    } else if (selectedvalue.ID == "PL") {
      this.lobIsCpmV2 = false;
      this.issuanceLOB = false;
    } else if (selectedvalue.ID == "RR") {
      this.lobIsCpmV2 = true;
      this.issuanceLOB = false;
    } else if (selectedvalue.ID == "BP") {
      this.lobIsCpmV2 = true;
      this.issuanceLOB = false;
    } else if (selectedvalue.ID == "GEL") {
      this.lobIsCpmV2 = false;
      this.issuanceLOB = false;
    } else if (selectedvalue.ID == "IM") {
      this.lobIsCpmV2 = true;
      this.issuanceLOB = false;
    } else if (selectedvalue.ID == "HRS") {
      this.lobIsCpmV2 = true;
      this.issuanceLOB = false;
    } else if (selectedvalue.ID == "EU") {
      this.lobIsCpmV2 = false;
      this.issuanceLOB = false;
    } else if (selectedvalue.ID == "ENV") {
      this.lobIsCpmV2 = true;
      this.issuanceLOB = false;
    } else if (selectedvalue.ID == "INTC") {
      this.selectedCov = "INTC";
      this.onCovChange(this.selectedCovList[0]);
      this.lobIsCpmV2 = true;
      this.issuanceLOB = true;
    } else if (selectedvalue.ID == "NP") {
      this.selectedCov = "NP";
      this.onCovChange(this.selectedCovList[0]);
      this.lobIsCpmV2 = true;
      this.issuanceLOB = true;
      this.isETaskLOB = true;
    } else if (selectedvalue.ID == "MIN") {
      this.selectedCov = "MINT";
      this.onCovChange(this.selectedCovList[0]);
      this.lobIsCpmV2 = true;
      this.issuanceLOB = true;
      this.isETaskLOB = true;
    } else if(selectedvalue.ID == "AUEL") {
      this.lobIsCpmV2 = true;
      this.issuanceLOB = false;
    } else if (selectedvalue.ID == "AUELF") {
      this.lobIsCpmV2 = true;
      this.issuanceLOB = false;
    } else if (selectedvalue.ID == "AUPL") {
      this.lobIsCpmV2 = true;
      this.issuanceLOB = false;
    } else if (selectedvalue.ID == "SHD") {
      this.lobIsCpmV2 = true;
      this.issuanceLOB = false;
    }
    this.uncheckAll();
  }
  //Reset filters for state, paper, coverage type and get list of forms for coverage
  onCovChange(selectedvalue) {
    this.selectedState = "";
    this.selectedPaper = "";
    this.selectedCovType = "";
    this.selectedStateForms = [];
    this.selectedPaperForms = [];
    this.selectedCovTypeForms = [];
    if (this.selectedCov == "INTC") {
      this.paperRuleList = this.bermudaPaperOnly;
      this.selectedPaper = "15";
    }
    else if (this.selectedCov == "MINT") {
      this.paperRuleList = this.evanstonPaperOnly;
      this.selectedPaper = "4";
    }
    else {
      this.paperRuleList = this.allPaperRules;
    }
    this.getPolicyFormMasterList(selectedvalue.ID);
    this.uncheckAll();
    console.log(this.paperRuleList);
  }

  onAuthRepChange(selectedvalue) {
    this.authRepPlaceholder = "";
    this.authorizedRepName = selectedvalue.Name;
    this.authorizedRepSurname = selectedvalue.Surname;
    this.authorizedRepTitle = selectedvalue.Title;
    this.selectedUnderwriter =
      this.authorizedRepName +
      " " +
      this.authorizedRepSurname +
      ", " +
      this.authorizedRepTitle;
  }

  //Call function to get primary/excess forms and save into list when getting LOB forms because it would take too long to process in real time on filter click
  GetExcessForms() {
    var isPrimary = true;
    //console.log("List");
    //console.log(this.policyFormList);
    this.excessForms = [];
    this.primaryForms = [];
    let rules: Rule[] = [];
    for (var i = 0; i < this.policyFormList.length; i++) {
      isPrimary = true;
      if (this.policyFormList[i].RuleSet != null) {
        rules = this.policyFormList[i].RuleSet.Rules;
      }

      for (var j = 0; j < rules.length; j++) {
        if (rules[j].Action != "Remove") {
          if (
            rules[j].Expressions.findIndex(
              (item) =>
                (item.ElementName == "Coverage" &&
                  (item.Operator == "in" || item.Operator == "=") &&
                  (item.IncludedList.some(
                    (r) => this.excessCodes.indexOf(r) >= 0
                  ) ||
                    item.MandatoryList.some(
                      (r) => this.excessCodes.indexOf(r) >= 0
                    ))) ||
                ((item.Operator == "all except" || item.Operator == "<>") &&
                  !(
                    item.IncludedList.some(
                      (r) => this.excessCodes.indexOf(r) >= 0
                    ) ||
                    item.MandatoryList.some(
                      (r) => this.excessCodes.indexOf(r) >= 0
                    )
                  ))
            ) != -1
          ) {
            this.excessForms.push(this.policyFormList[i]);
            isPrimary = false;
            break;
          }
        }
      }
      if (isPrimary) {
        this.primaryForms.push(this.policyFormList[i]);
      }
    }
    //console.log("Primary");
    //console.log(this.primaryForms);
    //console.log("Excess");
    //console.log(this.excessForms);
  }

  //On State and Paper change we have to check if other filters are applied, and if so adjust the master list accordingly
  //We can't just filter down the master list because the user could select a state or paper twice in a row, hence we need to get the full data each time and filter down
  onStateChange() {
    if (this.selectedCov != "INTC") {
      this.selectedStateForms = [];
      let rules: Rule[] = [];
      for (var i = 0; i < this.policyFormList.length; i++) {
        if (this.policyFormList[i].RuleSet != null) {
          rules = this.policyFormList[i].RuleSet.Rules;
        }

        for (var j = 0; j < rules.length; j++) {
          if (rules[j].Action != "Remove") {
            if (
              rules[j].Expressions.findIndex(
                (item) =>
                  (item.ElementName == "State" &&
                    (item.Operator == "in" || item.Operator == "=") &&
                    (item.IncludedList.includes(this.selectedState) ||
                      item.MandatoryList.includes(this.selectedState) ||
                      item.IncludedList.includes("ZZ") ||
                      item.MandatoryList.includes("ZZ"))) ||
                  ((item.Operator == "all except" || item.Operator == "<>") &&
                    !(
                      item.IncludedList.includes(this.selectedState) ||
                      item.MandatoryList.includes(this.selectedState)
                    ))
              ) != -1
            ) {
              this.selectedStateForms.push(this.policyFormList[i]);
              break;
            }
          }
        }
      }

      if (
        this.selectedCovTypeForms.length > 0 &&
        this.selectedStateForms.length > 0
      ) {
        this.policyFormList = _.intersectionBy(
          this.selectedStateForms,
          this.selectedCovTypeForms,
          "InternalFormNumber"
        );
        this.policyFormList = _.intersectionBy(
          this.policyFormList,
          this.selectedStateForms,
          "InternalFormNumber"
        );
      } else if (this.selectedPaperForms.length > 0) {
        this.policyFormList = _.intersectionBy(
          this.selectedStateForms,
          this.selectedPaperForms,
          "InternalFormNumber"
        );
      } else if (this.selectedCovTypeForms.length > 0) {
        this.policyFormList = _.intersectionBy(
          this.selectedStateForms,
          this.selectedCovTypeForms,
          "InternalFormNumber"
        );
      } else {
        this.policyFormList = this.selectedStateForms;
      }
      this.setRowData();
      this.formsGridOptions.api.onFilterChanged();
    }
  }

  onPaperChange() {
    console.log("PAPER " + this.selectedPaper);
    if (this.selectedCov != "INTC") {
      this.selectedPaperForms = [];
      this.policyFormList = [];
      let rules: Rule[] = [];
      for (var i = 0; i < this.selectedCovForms.length; i++) {
        if (this.selectedCovForms[i].RuleSet != null) {
          rules = this.selectedCovForms[i].RuleSet.Rules;
        }

        for (var j = 0; j < rules.length; j++) {
          if (rules[j].Action != "Remove") {
            if (
              rules[j].Expressions.findIndex(
                (item) =>
                  ((item.ElementName == "Writing Company" ||
                    item.ElementName == "WritingCompany") &&
                    (item.Operator == "in" || item.Operator == "=") &&
                    (item.IncludedList.includes(this.selectedPaper) ||
                      item.MandatoryList.includes(this.selectedPaper) ||
                      item.IncludedList.includes("ZZ") ||
                      item.MandatoryList.includes("ZZ"))) ||
                  ((item.Operator == "all except" || item.Operator == "<>") &&
                    !(
                      item.IncludedList.includes(this.selectedPaper) ||
                      item.MandatoryList.includes(this.selectedPaper)
                    ))
              ) != -1
            ) {
              this.selectedPaperForms.push(this.selectedCovForms[i]);
              break;
            }
          }
        }
      }

      if (
        this.selectedCovTypeForms.length > 0 &&
        this.selectedStateForms.length > 0
      ) {
        this.policyFormList = _.intersectionBy(
          this.selectedStateForms,
          this.selectedCovTypeForms,
          "InternalFormNumber"
        );
        this.policyFormList = _.intersectionBy(
          this.policyFormList,
          this.selectedPaperForms,
          "InternalFormNumber"
        );
      } else if (this.selectedStateForms.length > 0) {
        this.policyFormList = _.intersectionBy(
          this.selectedPaperForms,
          this.selectedStateForms,
          "InternalFormNumber"
        );
      } else if (this.selectedCovTypeForms.length > 0) {
        this.policyFormList = _.intersectionBy(
          this.selectedPaperForms,
          this.selectedCovTypeForms,
          "InternalFormNumber"
        );
      } else {
        this.policyFormList = this.selectedPaperForms;
      }
      this.setRowData();
      this.formsGridOptions.api.onFilterChanged();
    }
  }
  //Since IC users don't need to filter forms on any other conditions we can just filter the IC forms list based on the dec page here without needing to compare with other filters
  onDecPageChange() {
    let rules: Rule[] = [];
    this.selectedDecPageForms = [];
    if (this.selectedDecPage == "ZZ") {
      this.selectedDecPageForms = this.selectedCovForms;
    } else {
      for (var i = 0; i < this.selectedCovForms.length; i++) {
        if (this.selectedCovForms[i].RuleSet != null) {
          rules = this.selectedCovForms[i].RuleSet.Rules;
        }

        for (var j = 0; j < rules.length; j++) {
          if (rules[j].Action != "Remove") {
            if (
              rules[j].Expressions.findIndex(
                (item) =>
                  (item.ElementName == "ICDecPage" &&
                    (item.Operator == "in" || item.Operator == "=") &&
                    (item.IncludedList.includes(this.selectedDecPage) ||
                      item.MandatoryList.includes(this.selectedDecPage) ||
                      item.IncludedList.includes("ZZ") ||
                      item.MandatoryList.includes("ZZ"))) ||
                  ((item.Operator == "all except" || item.Operator == "<>") &&
                    !(
                      item.IncludedList.includes(this.selectedDecPage) ||
                      item.MandatoryList.includes(this.selectedDecPage)
                    ))
              ) != -1
            ) {
              this.selectedDecPageForms.push(this.selectedCovForms[i]);
              break;
            }
          }
        }
      }
    }
    this.policyFormList = this.selectedDecPageForms;
    this.setRowData();
    this.formsGridOptions.api.onFilterChanged();
  }

  //This one is a little different because we have to iterate through each rule for an excess coverage and append the results to a master list
  onCovTypeChange() {
    //Have to execute different logic based on value, if excess we can just intersect the filter lists like normal, but if primary we get the difference of the excess form list and other list(s)
    if (this.selectedCovType == "Excess") {
      this.selectedCovTypeForms = this.excessForms;
      if (
        this.selectedPaperForms.length > 0 &&
        this.selectedStateForms.length > 0
      ) {
        this.policyFormList = _.intersectionBy(
          this.selectedStateForms,
          this.selectedPaperForms,
          "InternalFormNumber"
        );
        this.policyFormList = _.intersectionBy(
          this.policyFormList,
          this.selectedCovTypeForms,
          "InternalFormNumber"
        );
      } else if (this.selectedStateForms.length > 0) {
        this.policyFormList = _.intersectionBy(
          this.selectedStateForms,
          this.selectedCovTypeForms,
          "InternalFormNumber"
        );
      } else if (this.selectedPaperForms.length > 0) {
        this.policyFormList = _.intersectionBy(
          this.selectedPaperForms,
          this.selectedCovTypeForms,
          "InternalFormNumber"
        );
      } else {
        this.policyFormList = this.selectedCovTypeForms;
      }
    } else if (this.selectedCovType == "Primary") {
      this.selectedCovTypeForms = this.primaryForms;
      if (
        this.selectedPaperForms.length > 0 &&
        this.selectedStateForms.length > 0
      ) {
        this.policyFormList = _.intersectionBy(
          this.selectedStateForms,
          this.selectedPaperForms,
          "InternalFormNumber"
        );
        this.policyFormList = _.intersectionBy(
          this.policyFormList,
          this.selectedCovTypeForms,
          "InternalFormNumber"
        );
      } else if (this.selectedStateForms.length > 0) {
        this.policyFormList = _.intersectionBy(
          this.selectedStateForms,
          this.selectedCovTypeForms,
          "InternalFormNumber"
        );
      } else if (this.selectedPaperForms.length > 0) {
        this.policyFormList = _.intersectionBy(
          this.selectedPaperForms,
          this.selectedCovTypeForms,
          "InternalFormNumber"
        );
      } else {
        this.policyFormList = this.selectedCovTypeForms;
      }
    }
    this.formsGridOptions.api.onFilterChanged();
    this.setRowData();
  }

  clearAll() {
    this.selectedState = "";
    this.selectedPaper = "";
    this.selectedCovType = "";
    this.selectedDecPage = "";
    this.searchInput = "";
    this.filter = "default";
    this.isMandatory = "All";
    this.selectedStateForms = [];
    this.selectedPaperForms = [];
    this.selectedCovTypeForms = [];
    this.selectedFormCount = 0;
    //this.getRuleTypeDetailList();
    this.policyFormList = this.selectedCovForms;
    this.setRowData();
    this.formsGridOptions.api.onFilterChanged();
  }

  uncheckAll() {
    this.formsGridOptions.api.deselectAll();
    this.checkedFormsFulls = [];
    this.setSelectedForms([]);
  }

  clearTransaction() {
    this.clearAll();
    this.uncheckAll();
    this.clearIssuanceFillins();
    this.selectedTransactionId = null;
    this.currentTransaction = null;
    this.isExistingTransaction = false;
  }

  clearIssuanceFillins() {
    this.policyNumber = "";
    this.policyEffectiveDate = null;
    this.policyExpirationDate = null;
    this.insuredName = "";
    this.insuredDBA = "";
    this.insuredAddress = "";
    this.insuredAddress2 = "";
    this.insuredAddress3 = "";
    this.producerName = "";
    this.authorizedRepName = "";
    this.authorizedRepSurname = "";
    this.authorizedRepTitle = "";
    this.dealNumber = "";

    this.selectedUnderwriter = "";
    this.authRepPlaceholder = "Click to Select";
  }

  downloadPolicy(isPreview: boolean) {
    var formNames: string = ``;
    this.checkedForms.forEach((form) => {
      formNames += `"${form}",`;
    });
    //After compiling list of form names remove trailing comma
    formNames = formNames.substr(0, formNames.length - 1);
    //var selectedForms: string [] = [];
    this.downloadForm(formNames, isPreview, false, false);
  }

  onRowClicked(e) {
    if (e.event.target !== undefined) {
      var selectedFormsRuleEngine: RuleEngineForm[] = [];
      var formsdata = e.data;
      selectedFormsRuleEngine.push(formsdata);
      console.log(formsdata);
      var isMol = formsdata.ImportFromMOL as boolean;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Download":
          if (formsdata.FormName.includes("DOCX")) {
            return this.downloadForm(
              `"${formsdata.InternalFormNumber}"`,
              false,
              true,
              isMol
              
              
            );
          } else {
            return this.downloadForm(
              `"${formsdata.InternalFormNumber}"`,
              false,
              false,
              isMol,
              selectedFormsRuleEngine
              

            );
          }
        case "Preview":
          if (formsdata.FormName.toUpperCase().includes("DOCX")) {
            return this.downloadForm(
              `"${formsdata.InternalFormNumber}"`,
              true,
              true,
              isMol
              

            );
          } else {
            return this.downloadForm(
              `"${formsdata.InternalFormNumber}"`,
              true,
              false,
              isMol,
              selectedFormsRuleEngine              

            );
          }
      }
    }
  }

  //When selecting a form it adds it to the "cart", checks against list of selected forms to avoid duplicates
  onSelectionChanged() {
    let rowsSelected = this.formsGridOptions.api.getSelectedRows();
    let tempCheckedForms: string[] = rowsSelected.map(
      (a) => a.InternalFormNumber
    );
    let tempCheckedFormsFulls: RuleEngineForm[] = rowsSelected.map((b) => b);

    this.checkedForms = _.uniq([...this.checkedForms, ...tempCheckedForms]);
    this.checkedFormsFulls = _.uniqBy(
      [...this.checkedFormsFulls, ...tempCheckedFormsFulls],
      "InternalFormNumber"
    );
    this.selectedFormCount = this.checkedForms.length;
  }

  //Private Methods
  /**
   * configure grid
   */
  private configureGrid(): void {
    this.initialRowDataLoad$ = [];
    this.formsGridOptions = <GridOptions>{
      rowSelection: "multiple",
      domLayout: "normal",
      floatingFilter: true,
      columnDefs: this.createColumDef(),
      enableFilter: true,
      enableSorting: true,
      isExternalFilterPresent: this.isExternalFilterPresent.bind(this),
      doesExternalFilterPass: this.doesExternalFilterPass.bind(this),
      pagination: true,
      enableColResize: true,
      paginationPageSize: 10,
      suppressRowClickSelection: true,
      suppressMenuHide: true,
      alwaysShowVerticalScroll : true,
      defaultColDef: {
        cellStyle: { textAlign: 'left' }
      },
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: () => {
        this.formsGridOptions.api.sizeColumnsToFit();
        this.formsGridOptions.rowHeight = 60;
        if (this.policyFormList.length > 0) {
          this.formsGridOptions.api.setRowData(this.policyFormList);
        } else {
          this.formsGridOptions.api.setRowData([]);
        }
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        this.onRowClicked(event);
      },
      onSelectionChanged: (event) => {
        this.onSelectionChanged();
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        console.log(event);
      },
    };
  }

  /**
   * Configure Columns
   */
  private createColumDef(): any[] {
    var isIC = this.authService.isIC();
    let result: any[] = [
      {
        headerName: "",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        field: "RowSelect",
        suppressMenu: true,
        suppressSorting: true,
        width: 42,
        filter: "booleanFilterComponent",
      },
      {
        headerName: "Internal Form Number",
        field: "InternalFormNumber",
        suppressMenu: true,
        width: 140,
        resizable: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Form Name",
        field: "FormName",
        suppressMenu: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true
        },
        width: 260,
        resizable: true,
        cellStyle: { "justify-content": "left", "white-space": "normal" },
        autoHeight: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Effective Date",
        field: "EffectiveDate",
        suppressMenu: true,
        sortable: true,
        width: 90,
        resizable: true,
        filter: "dateFilterComponent",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(params.value);
        },
      },
      {
        headerName: "Expiration Date",
        field: "ExpirationDate",
        suppressMenu: true,
        sortable: true,
        width: 100,
        resizable: true,
        filter: "dateFilterComponent",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(params.value);
        },
      },
      {
        headerName: "Submission Exp Date",
        field: "SubmissionExpirationDate",
        suppressMenu: true,
        sortable: true,
        width: 100,
        resizable: true,
        filter: "dateFilterComponent",
        hide: isIC,
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(params.value);
        },
      },
      {
        headerName: "Form Type",
        field: "FormType",
        width: 95,
        resizable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
      },
      {
        headerName: "Mandatory",
        suppressSorting: true,
        field: "Mandatory",
        width: 90,
        resizable: true,
        cellRenderer: (params) => {
          return params.value ? "Yes" : "No";
        },
      },
      {
        headerName: "In Multiple Coverages",
        suppressSorting: true,
        suppressMenu: true,
        field: "MultiLOBIndicator",
        width: 90,
        resizable: true,
        filter: "booleanFilterComponent",
        cellRenderer: (params) => {
          return params.value ? "Yes" : "No";
        },
      },
      {
        headerName: "Actions",
        width: 70,
        suppressMenu: true,
        suppressSorting: true,
        filter: "booleanFilterComponent",
        cellRenderer: (params) => {
          if (isIC) {
            return `<img width="20px" src="/assets/images/pdf-logo.png" data-action-type="Preview" class="cursor_pointer" title="Preview">`;
          } else {
            return `<img width="20px" src="/assets/images/d.png" data-action-type="Download" class="cursor_pointer mrgrgt10" title="Download">
                    <img width="20px" src="/assets/images/pdf-logo.png" data-action-type="Preview" class="cursor_pointer" title="Preview">`;
          }
        },
      },
    ];

    return result;
  }

  //Download a form or list of forms, done by taking raw return data and converting into blob with type pdf
  // if isPreview open the form in a new tab instead
  private downloadForm(
    policyForms: string,
    isPreview: boolean,
    isDocX: boolean,
    isMol: boolean,
    adminForms?: RuleEngineForm[],
  ) {
    console.log(isMol);
    if (isMol) {
      this.formsService.getAllMolForms(adminForms).subscribe(
        (res) => {
          console.log(Object.values(res));
          var response = Object.values(res)[0] as string;

          const byteCharacters = atob(response);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          let blob: Blob;
          blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          //window.open(res);
          let url = URL.createObjectURL(blob);
          window.open(url);
        }) 
    } else {
      this.formsService.getJwt().subscribe((t) => {
        console.log(t);
        this.formsService.downloadSpecimen(policyForms, t, isDocX, isMol).subscribe(
          (res) => {
            const byteCharacters = atob(res[0].Content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            let blob: Blob;
            if (isDocX) {
              blob = new Blob([byteArray], {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              });
            } else {
              blob = new Blob([byteArray], { type: "application/pdf" });
            }
            //Need logic specifically for IE because its a special little boy that refuses to handle blob files
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              if (!isPreview) {
                window.navigator.msSaveOrOpenBlob(blob, "Ghostportal_Form.pdf");
              } else {
                window.navigator.msSaveOrOpenBlob(blob, "Ghostportal_Form.pdf");
              }
            } else {
              var downloadURL = window.URL.createObjectURL(blob);
              if (isPreview) {
                window.open(downloadURL, "_blank");
              } else {
                var link = document.createElement("a");
                link.href = downloadURL;
                link.download = "GhostPortal_Form.pdf";
                link.click();
              }
            }
          },
          (err) => {
            console.log(err);
            this.toastr.error(err.error, "Ghostdraft Generation Error");
          }
        );
      });
    }
  }

  /**
   * Get Master list of Policy Forms based on lob name
   * @param lobName
   */
  private async getPolicyFormMasterList(lobName: string) {
    /*Check if lob name contains '&' symbol then encode with %26.This will fix value truncating issue with querstring parameter */
    //if (listName.indexOf('&') > -1) {
    // listName = listName.replace("&", "%26");
    //}

    await this.formsService.getAdminFormsForLOB(lobName).subscribe(
      (res: AdminForm[]) => {
        this.selectedCovForms = [];
        var adminForms = res;
        this.policyFormList = [];
        //Take attribute set of current form and put all relevant info into Rule Engine Form data structure for display and deploying
        for (var item of adminForms) {
          let ruleEngineForm = new RuleEngineForm();
          var lobSet = item.AttributeSets.find(
            (element) => element.Scope == lobName
          );
          if (lobSet != null) {
            ruleEngineForm.AdminFormId = item.id;
            ruleEngineForm.InternalFormNumber = item.InternalFormNumber;
            ruleEngineForm.ExternalFormNumber = item.ExternalFormNumber;
            ruleEngineForm.MultiLOBIndicator = item.MultiLOBIndicator;
            ruleEngineForm.CreationDate = item.CreationDate;
            ruleEngineForm.CreatedByName = item.CreatedByName;
            ruleEngineForm.ModifiedDate = item.ModifiedDate;
            ruleEngineForm.ModifiedByName = item.ModifiedByName;
            ruleEngineForm.LOB = lobName;
            ruleEngineForm.ImportFromMOL = item.ImportFromMOL;
            ruleEngineForm.MolDeptId = item.MolDeptId;
            ruleEngineForm.MolEditionDate = item.MolEditionDate;
            ruleEngineForm.MolEffectiveDate = item.MolEffectiveDate;
            ruleEngineForm.MolId = item.MolId;
            ruleEngineForm.MolNumber = item.MolNumber;

            if (
              item.RuleSetups.find((set) => set.LOB.indexOf(lobName) != -1) !=
              null
            ) {
              ruleEngineForm.RuleSet = item.RuleSetups.find(
                (set) => set.LOB.indexOf(lobName) != -1
              ).RuleSet;
            }


            ruleEngineForm.FormName = lobSet.FormName;
            ruleEngineForm.SortOrder = lobSet.SortOrder;
            ruleEngineForm.FormType = lobSet.FormType;
            ruleEngineForm.EffectiveDate = lobSet.EffectiveDate;
            ruleEngineForm.ExpirationDate = lobSet.ExpirationDate;
            ruleEngineForm.SubmissionExpirationDate =
              lobSet.SubmissionExpirationDate;
            ruleEngineForm.FrequentlyUsed = lobSet.FrequentlyUsed;
            var mandatory = false;
            var selected = false;
            if (
              item.RuleSetups.find(
                (element) => element.LOB.indexOf(lobName) != -1
              ) != undefined
            ) {
              for (var rule of item.RuleSetups.find(
                (element) => element.LOB.indexOf(lobName) != -1
              ).RuleSet.Rules) {
                if (rule.Expressions.some((x) => x.MandatoryList.length > 0)) {
                  mandatory = true;
                }
                //If logging do some auto selecting based off of rules
                if (this.selectedCov == "NP") {
                  var expressions = rule.Expressions.filter(
                    (ex) => ex.ElementName == "IsDefaultSelection"
                  );
                  if (expressions.length > 0) {
                    if (
                      expressions[0].IncludedList.includes("true") ||
                      expressions[0].MandatoryList.includes("true")
                    ) {
                      selected = true;
                    }
                  }
                }
              }
            }
            ruleEngineForm.DefaultSelect = selected;
            ruleEngineForm.Mandatory = mandatory;
            this.policyFormList.push(ruleEngineForm);
            this.selectedCovForms.push(ruleEngineForm);
          }
        }
        this.setRowData();
        this.GetExcessForms();
        if (
          this.selectedCov == "INTC" &&
          this.selectedDecPage != null &&
          this.selectedDecPage != ""
        ) {
          console.log(this.selectedDecPage);
          this.onDecPageChange();
        }
      },(err) => {
        console.log(err);
        this.showError();
      }
          );
  }

  /**
   *Set data to grid
   * */
  private setRowData() {
    if (this.policyFormList != null) {
      this.policyFormList.sort((a: any, b: any) => {
        var nameA = a.FormName.toLowerCase();
        var nameB = b.FormName.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.formsGridOptions.api.setRowData(this.policyFormList);
      this.selectDefault();
    }
  }

  private selectDefault() {
    this.formsGridOptions.api.forEachNode((node) => {
      if (node.data.DefaultSelect) {
        node.selectThisNode(true);
      }
    });
    this.onSelectionChanged();
  }

  isExternalFilterPresent() {
    if (
      this.selectedState ||
      this.selectedPaper ||
      this.selectedCovType ||
      this.searchInput ||
      this.isMandatory
    ) {
      return true;
    } else {
      return false;
    }
  }
  onSearchKey(event) {
    if (event.keyCode === 13) {
      this.externalFilterChanged(event, "search");
    }
  }

  onMandatoryOptional() {
    this.externalFilterChanged(this.isMandatory, "isMandatory");
  }

  externalFilterChanged(selectedValue, filter) {
    this.filter = filter;
    switch (filter) {
      case "state":
        this.onStateChange();
        break;
      case "paper":
        this.onPaperChange();
        break;
      case "covtype":
        this.onCovTypeChange();
        break;
      case "search":
        this.formsGridOptions.api.onFilterChanged();
        break;
      case "isMandatory":
        this.formsGridOptions.api.onFilterChanged();
        break;
    }
  }

  //Used for the filter options outside of the grid, checks search criteria and filters accordingly
  doesExternalFilterPass(node) {
    if (this.filter == "default") {
      return true;
    } else if (this.filter == "search") {
      if (this.isMandatory != "All") {
        if (this.isMandatory == "Mandatory" && !node.data.Mandatory) {
          return false;
        } else if (this.isMandatory == "Optional" && node.data.Mandatory) {
          return false;
        }
      }
      if (this.selectedInput) {
        return node.data.InternalFormNumber.toLowerCase().includes(
          this.searchInput.toLowerCase()
        ) || node.data.FormName.toLowerCase().includes(
          this.searchInput.toLowerCase()
        );
      }
    } else if (this.filter == "isMandatory") {
      
      if (this.searchInput && this.searchInput.length > 0) {
        console.log(this.searchInput);
        // if (
        //   this.selectedInput == "formnum" &&
        //   !node.data.InternalFormNumber.toLowerCase().includes(
        //     this.searchInput.toLowerCase()
        //   )
        // ) {
        //   return false;
        // } else if (
        //   this.selectedInput == "formname" &&
        //   !node.data.FormName.toLowerCase().includes(
        //     this.searchInput.toLowerCase()
        //   )
        // ) {
        //   return false;
        // }
        if (
          !node.data.InternalFormNumber.toLowerCase().includes(
            this.searchInput.toLowerCase()
          ) && !node.data.FormName.toLowerCase().includes(
            this.searchInput.toLowerCase()
          )
        ) {
          return false;
        }
      }
      if (this.isMandatory == "All") {
        return true;
      } else
      if (this.isMandatory == "Mandatory") {
        return node.data.Mandatory;
      } else if (this.isMandatory == "Optional") {
        return !node.data.Mandatory;
      } else {
        return true;
      }
      
    }
    return (
      this.policyFormList.findIndex(
        (item) => item.AdminFormId === node.data.AdminFormId
      ) > -1
    );
  }

  getMissingDataMessages(): string[] {
    var msg = [];
    if (this.policyNumber == "" || this.policyNumber == null)
      msg.push("Policy Number");
    // if (this.selectedState == "" || this.selectedState == null) msg.push("Policy State");
    if (this.selectedPaper == "" || this.selectedPaper == null)
      msg.push("Policy Paper");
    if (this.insuredName == "" || this.insuredName == null)
      msg.push("Insured Name");
    //if (this.insuredDBA == "") msg.push("Insured DBA");
    if (this.insuredAddress == "" || this.insuredAddress == null)
      msg.push("Insured Address");
    if (this.insuredAddress2 == "" || this.insuredAddress2 == null)
      msg.push("Insured Address 2P");
    //if (this.insuredAddress3 == "" || this.insuredAddress3 == null) msg.push("Insured Address 3P");
    if (this.producerName == "" || this.producerName == null)
      msg.push("Producer Name");
    if (this.policyEffectiveDate == "" || this.policyEffectiveDate == null)
      msg.push("Policy Effective Date");
    if (this.policyExpirationDate == "" || this.policyExpirationDate == null)
      msg.push("Policy Expiration Date");
    if (this.authorizedRepName == "" || this.authorizedRepName == null)
      msg.push("Authorized Rep. Name");
    if (this.authorizedRepSurname == "" || this.authorizedRepSurname == null)
      msg.push("Authorized Rep. Surname");
    if (this.authorizedRepTitle == "" || this.authorizedRepTitle == null)
      msg.push("Authoirzed Rep. Title");

    return msg;
  }

  openExtensionIframe(isETask: boolean): void {
    if (this.checkedFormsFulls != null && this.checkedFormsFulls.length > 0) {
      var missingDataMessages = this.getMissingDataMessages();
      if (missingDataMessages.length == 0) {
        if (this.isExistingTransaction && this.checkChangedFields()) {
          if (
            confirm(
              "You have changed information for this policy.\nClick OK to start a new policy with this information.(This transaction will be deleted)\nClick Cancel to undo changes."
            )
          ) {
            var id = this.selectedTransactionId;
            // if confirmed, bind all fields to a new transaction and open extension
            this.formsService.getBaseCpm(this.lobIsCpmV2).subscribe((data) => {
              let rulesForms = this.checkedFormsFulls;
              var cpmFormsData = this.setCpmForms(rulesForms);
              let transId = uuidv4();

              // capture policy data
              let cpmElements = this.getCpmElements();
              this.formsService.getExtensionJWT().subscribe((token) => {
                this.formsService
                  .createExtensionTransaction(
                    cpmFormsData,
                    data,
                    transId,
                    cpmElements,
                    this.userProfileService.UserName,
                    token,
                    this.dealNumber,
                    this.selectedLOB
                  )
                  .subscribe(
                    (res) => {
                      const modalRef = this._modal.open(Extension, {
                        windowClass: "issuance-modal",
                        backdrop: "static",
                      });
                      modalRef.componentInstance.transactionId = transId;
                      modalRef.result.then((result) => {
                        this.uncheckAll();
                        this.clearIssuanceFillins();
                        this.isExistingTransaction = false;
                      });
                      this.formsService
                        .deleteTransactionData(id)
                        .subscribe((res) => {
                          console.log(res);
                        });
                    },
                    (error) => {
                      let err: HttpErrorResponse = error;
                      this.toastr.error(
                        err.error,
                        "Ghostdraft Issuance Error",
                        { positionClass: "toast-bottom-right" }
                      );
                    }
                  );
              });
            });
          } else {
            // else, return to forms screen and change fields back to old values
            this.undoPolicyChanges();
          }
        } else {
          this.formsService.getBaseCpm(this.lobIsCpmV2).subscribe((data) => {
            let rulesForms = this.checkedFormsFulls;
            var cpmFormsData = this.setCpmForms(rulesForms);
            let transId = uuidv4();

            // capture policy data
            let cpmElements = this.getCpmElements();

            //check if existing transaction is selected
            if (
              this.isExistingTransaction &&
              this.selectedTransactionId != null
            ) {
              transId = this.selectedTransactionId;
              const modalRef = this._modal.open(Extension, {
                windowClass: "issuance-modal",
                backdrop: "static",
              });
              modalRef.componentInstance.transactionId = transId;
              modalRef.result.then((result) => {
                this.uncheckAll();
                this.clearIssuanceFillins();
                this.isExistingTransaction = false;
                if (isETask) {
                  var etaskmodel = new ETaskRequest();
                  etaskmodel.SourceSystem = "Ghostdraft";
                  etaskmodel.LineOfBusiness = this.selectedLOB;
                  etaskmodel.CoverageNames = this.selectedCov;
                  etaskmodel.DealType = this.currentTransaction.IsRenewal ? "RENEW" : "NEW";
                  etaskmodel.DealNumber = this.dealNumber;
                  etaskmodel.PolicyNumber = this.policyNumber;
                  etaskmodel.DealStatus = "Issued";
                  etaskmodel.Description = "ForAutomationOnly";
                  etaskmodel.ExpirationDate = this.policyExpirationDate;
                  etaskmodel.InceptionDate = this.policyEffectiveDate;
                  etaskmodel.UnderwritingTeam = this.selectedUnderwriter;
                  etaskmodel.TaskType = "Import Locations";
                  etaskmodel.UniqueNumber = Math.floor(100000 + Math.random() * 900000).toString();
                  etaskmodel.Insured = new InsuredInfo();
                  etaskmodel.Insured.Auditable = "NO";
                  etaskmodel.Insured.AuditFrequency = "N/A";
                  etaskmodel.Broker = new BrokerInfo();
                  etaskmodel.Broker.BrokerType = "Wholesale";
                  this.formsService.sendETask(etaskmodel).subscribe(res => console.log(res));
                }
              });
            } else {
              this.formsService.getExtensionJWT().subscribe((token) => {
                console.log(token);
                this.formsService
                  .createExtensionTransaction(
                    cpmFormsData,
                    data,
                    transId,
                    cpmElements,
                    this.userProfileService.UserName,
                    token,
                    this.dealNumber,
                    this.selectedLOB
                  )
                  .subscribe(
                    (res) => {
                      const modalRef = this._modal.open(Extension, {
                        windowClass: "issuance-modal",
                        backdrop: "static",
                      });
                      modalRef.componentInstance.transactionId = transId;
                      modalRef.result.then((result) => {
                        this.uncheckAll();
                        this.clearIssuanceFillins();
                        this.isExistingTransaction = false;
                        if (isETask) {
                          var etaskmodel = new ETaskRequest();
                          etaskmodel.SourceSystem = "Ghostdraft";
                          etaskmodel.LineOfBusiness = this.selectedLOB;
                          etaskmodel.CoverageNames = this.selectedCov;
                          etaskmodel.DealType = this.currentTransaction.IsRenewal ? "RENEW" : "NEW";
                          etaskmodel.DealNumber = this.dealNumber;
                          etaskmodel.PolicyNumber = this.policyNumber;
                          etaskmodel.DealStatus = "Issued";
                          etaskmodel.Description = "ForAutomationOnly";
                          etaskmodel.ExpirationDate = this.policyExpirationDate;
                          etaskmodel.InceptionDate = this.policyEffectiveDate;
                          etaskmodel.UnderwritingTeam = this.selectedUnderwriter;
                          etaskmodel.TaskType = "Import Locations";
                          etaskmodel.UniqueNumber = Math.floor(100000 + Math.random() * 900000).toString();
                          etaskmodel.Insured.Auditable = "NO";
                          etaskmodel.Insured.AuditFrequency = "N/A";
                          etaskmodel.Broker.BrokerType = "Wholesale";
                          this.formsService.sendETask(etaskmodel).subscribe(res => console.log(res));
                        }
                      });
                    },
                    (error) => {
                      let err: HttpErrorResponse = error;
                      this.toastr.error(
                        err.error,
                        "Ghostdraft Issuance Error",
                        { positionClass: "toast-bottom-right" }
                      );
                    }
                  );
              });
            }
          });
        }
      } else {
        //let missingDataString = "";
        //missingDataMessages.forEach(s => missingDataString = missingDataString.concat(`${s}\n`));
        //this.toastr.error(`Missing policy information: ${missingDataString}`, "Issuance Error", { positionClass: 'toast-bottom-right' });
        missingDataMessages.forEach((msg) => {
          this.toastr.error(
            `Missing policy information: ${msg}`,
            "Issuance Error",
            { positionClass: "toast-bottom-right" }
          );
        });
      }
    } else {
      this.toastr.error(
        "Select forms before issuing policy.",
        "Issuance Error",
        { positionClass: "toast-bottom-right" }
      );
    }
  }

  undoPolicyChanges(): void {
    let t = this.currentTransaction;
    this.selectedState = t.PolicyState;
    this.selectedPaper = t.WritingCompanyCode;
    this.insuredName = t.NamedInsured;
    this.insuredDBA = t.InsuredDBA;
    this.insuredAddress = t.InsuredAddress;
    this.insuredAddress2 = t.InsuredAddress2P;
    this.insuredAddress3 = t.InsuredAddress3P;
    this.producerName = t.ProducerName;
    this.policyNumber = t.PolicyNumber;
    this.authorizedRepName = t.AuthorizedRepName;
    this.authorizedRepSurname = t.AuthorizedRepSurname;
    this.authorizedRepTitle = t.AuthorizedRepTitle;
    this.dealNumber = t.DealNumber;

    this.selectedUnderwriter =
      this.authorizedRepName +
      " " +
      this.authorizedRepSurname +
      ", " +
      this.authorizedRepTitle;
    this.authRepPlaceholder = "";

    this.policyEffectiveDate = t.PolicyEffectiveDate;
    (<HTMLInputElement>document.getElementById("polEffDate")).value =
      t.PolicyEffectiveDate;

    this.policyExpirationDate = t.PolicyExpirationDate;
    (<HTMLInputElement>document.getElementById("polExpDate")).value =
      t.PolicyExpirationDate;

    this.formsGridOptions.api.forEachNode((node) => {
      if (t.Forms.indexOf(node.data["InternalFormNumber"]) != -1) {
        node.setSelected(true);
      } else {
        node.setSelected(false);
      }
    });
  }

  checkChangedFields(): boolean {
    let t = new TransactionResponse();
    t.PolicyState = this.selectedState;
    t.WritingCompanyCode = this.currentTransaction.WritingCompanyCode;
    t.WritingCompanyName = this.currentTransaction.WritingCompanyName;
    t.UserName = this.currentTransaction.UserName;
    t.IsIssued = false;
    t.IssuedOn = null;
    t.NamedInsured = this.insuredName;
    t.InsuredDBA = this.insuredDBA;
    t.InsuredAddress = this.insuredAddress;
    t.InsuredAddress2P = this.insuredAddress2;
    t.InsuredAddress3P = this.insuredAddress3;
    t.DealNumber = this.dealNumber;
    t.ProducerName = this.producerName;
    t.AuthorizedRepName = this.authorizedRepName;
    t.AuthorizedRepSurname = this.authorizedRepSurname;
    t.AuthorizedRepTitle = this.authorizedRepTitle;
    t.PolicyNumber = this.policyNumber;
    t.PolicyEffectiveDate = this.policyEffectiveDate;
    t.PolicyExpirationDate = this.policyExpirationDate;
    t.CreatedOn = this.currentTransaction.CreatedOn;
    var forms: string[] = [];
    this.checkedFormsFulls.forEach((a) => forms.push(a.InternalFormNumber));
    t.Forms = forms;
    t.TransactionId = this.selectedTransactionId;

    const keys1 = Object.keys(this.currentTransaction);
    const keys2 = Object.keys(t);
    for (let key of keys1) {
      if (this.currentTransaction[key] == undefined || t[key] == undefined) {
        continue;
      } else if (key == "Forms") {
        let forms1 = t.Forms;
        let forms2 = this.currentTransaction.Forms;
        if (!this.stringArrayEquals(forms1, forms2)) {
          return true;
        }
      } else if (this.currentTransaction[key] !== t[key]) {
        return true;
      }
    }
    return false;
  }
  stringArrayEquals(a: string[], b: string[]): boolean {
    let result = true;
    let sameLength = a.length === b.length;
    if (sameLength) {
      a.forEach((f) => {
        if (b.indexOf(f) == -1) {
          result = false;
        }
      });
    } else {
      result = false;
    }
    return result;
  }

  //Open Transactions modal and display transaction list
  openTransactions(): void {
    var transactions: TransactionResponse[] = [];
    this.formsService.getAllTransactions(this.selectedLOB).subscribe(
      (res) => {
        transactions = res;
        const modalRef = this._modal.open(Transactions, {
          windowClass: "transactions-modal",
        });
        modalRef.componentInstance.userTransList = transactions;
        modalRef.componentInstance.lobForms = this.selectedCovForms;
        modalRef.result.then((result: TransactionResponse) => {
          if (result) {
            console.log(result);

            if (result.IsRenewal) {
              const modalRef = this._modal.open(Extension, {
                windowClass: "issuance-modal",
                backdrop: "static",
              });
              modalRef.componentInstance.transactionId = result.TransactionId;
              modalRef.result.then((result) => {
                this.uncheckAll();
                this.clearIssuanceFillins();
                this.isExistingTransaction = false;
              });
            } else {
              this.currentTransaction = result;
              this.currentTransaction.IssuedOn = null;
              this.currentTransaction.IsIssued = false;
              this.insuredName = result.NamedInsured;
              this.insuredDBA = result.InsuredDBA;
              this.insuredAddress = result.InsuredAddress;
              this.insuredAddress2 = result.InsuredAddress2P;
              this.insuredAddress3 = result.InsuredAddress3P;
              this.dealNumber = result.DealNumber;
              this.producerName = result.ProducerName;
              this.policyNumber = result.PolicyNumber;
              this.selectedState = result.PolicyState;
              this.authorizedRepName = result.AuthorizedRepName;
              this.authorizedRepSurname = result.AuthorizedRepSurname;
              this.authorizedRepTitle = result.AuthorizedRepTitle;

              this.selectedUnderwriter =
                this.authorizedRepName +
                " " +
                this.authorizedRepSurname +
                ", " +
                this.authorizedRepTitle;
              this.authRepPlaceholder = "";

              var effDateString = this.convertToDateString(
                result.PolicyEffectiveDate
              );
              this.policyEffectiveDate = effDateString;
              (<HTMLInputElement>document.getElementById("polEffDate")).value =
                effDateString;
              this.currentTransaction.PolicyEffectiveDate = effDateString;

              var expDateString = this.convertToDateString(
                result.PolicyExpirationDate
              );
              this.policyExpirationDate = expDateString;
              (<HTMLInputElement>document.getElementById("polExpDate")).value =
                expDateString;
              this.currentTransaction.PolicyExpirationDate = expDateString;

              for (var i = 0; i < this.paperRuleList.length; i++) {
                var paperRule = this.paperRuleList[i];
                let wcName = String(paperRule["Description"]);
                console.log(result.WritingCompanyName);
                console.log(result.WritingCompanyCode);
                if (wcName.indexOf(result.WritingCompanyName) != -1) {
                  this.selectedPaper = String(paperRule["Code"]);
                  this.currentTransaction.WritingCompanyCode = String(
                    paperRule["Code"]
                  );
                }
              }

              // find forms to set through various lists
              this.formsGridOptions.api.deselectAll();
              this.setSelectedForms(result.Forms);

              this.selectedTransactionId = result.TransactionId;
              this.isExistingTransaction = true;
            }
          }
        });
      },
      (error) => {
        let err: HttpErrorResponse = error;
        this.toastr.error(err.error, "Error", {
          positionClass: "toast-bottom-right",
        });
      }
    );
  }

  convertToDateString(str: string) {
    var date = new Date(str);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let dayString = day.toString();
    if (dayString.length == 1) dayString = "0" + dayString;
    let monthString = month.toString();
    if (monthString.length == 1) monthString = "0" + monthString;
    let yyyy_MM_dd = year + "-" + monthString + "-" + dayString;
    return yyyy_MM_dd;
  }

  setSelectedForms(forms: string[]): void {
    this.checkedForms = [];
    this.checkedFormsFulls = [];
    let selectedForms: RuleEngineForm[] = [];
    for (var i = 0; i < this.excessForms.length; i++) {
      let ef: RuleEngineForm = this.excessForms[i];
      let formNumber = String(ef["InternalFormNumber"]);
      if (forms.includes(formNumber)) {
        selectedForms.push(ef);
      }
    }
    for (var j = 0; j < this.primaryForms.length; j++) {
      let pf: RuleEngineForm = this.primaryForms[j];
      let formNumber = String(pf["InternalFormNumber"]);
      if (forms.includes(formNumber)) {
        selectedForms.push(pf);
      }
    }
    selectedForms.forEach((f) => {
      this.checkedForms.push(f["InternalFormNumber"]);
      this.checkedFormsFulls.push(f);
    });
    this.formsGridOptions.api.forEachNode((node) => {
      if (forms.indexOf(node.data["InternalFormNumber"]) != -1) {
        node.setSelected(true);
      }
    });

    this.selectedFormCount = selectedForms.length;
  }

  getCpmElements(): CPMElement[] {
    var result: CPMElement[] = [];

    // Policy Number
    if (this.policyNumber != "") {
      let pnElem: CPMElement = new CPMElement();
      pnElem.Name = "PolicyNumber";
      pnElem.Value = this.policyNumber;
      result.push(pnElem);
    }

    if (this.producerName != "") {
      let pnElem: CPMElement = new CPMElement();
      pnElem.Name = "ProducerName";
      pnElem.Value = this.producerName;
      result.push(pnElem);
    }

    // Insured Name
    if (this.insuredName != "") {
      let inElem: CPMElement = new CPMElement();
      inElem.Name = "NamedInsured";
      inElem.Value = this.insuredName;
      result.push(inElem);
    }

    if (this.insuredDBA != "") {
      let inElem: CPMElement = new CPMElement();
      inElem.Name = "InsuredDBA";
      inElem.Value = this.insuredDBA;
      result.push(inElem);
    }

    // Insured Address elements
    if (this.insuredAddress != "") {
      let iaElem: CPMElement = new CPMElement();
      iaElem.Name = "InsuredAddress";
      iaElem.Value = this.insuredAddress;
      result.push(iaElem);
    }

    if (this.insuredAddress2 != "") {
      let icElem: CPMElement = new CPMElement();
      icElem.Name = "InsuredAddress2P";
      icElem.Value = this.insuredAddress2;
      result.push(icElem);
    }

    if (this.insuredAddress3 != "") {
      let isElem: CPMElement = new CPMElement();
      isElem.Name = "InsuredAddress3P";
      isElem.Value = this.insuredAddress3;
      result.push(isElem);
    }

    if (this.dealNumber != "") {
      let isElem: CPMElement = new CPMElement();
      isElem.Name = "DealNumber";
      isElem.Value = this.dealNumber;
      result.push(isElem);
    }

    if (this.authorizedRepName != "") {
      let isElem: CPMElement = new CPMElement();
      isElem.Name = "AuthorizedRepName";
      isElem.Value = this.authorizedRepName;
      result.push(isElem);
    }

    if (this.authorizedRepSurname != "") {
      let isElem: CPMElement = new CPMElement();
      isElem.Name = "AuthorizedRepSurname";
      isElem.Value = this.authorizedRepSurname;
      result.push(isElem);
    }

    if (this.authorizedRepTitle != null) {
      let peffElem: CPMElement = new CPMElement();
      peffElem.Name = "AuthorizedRepTitle";
      peffElem.Value = this.authorizedRepTitle;
      result.push(peffElem);
    }
    // Policy period elements

    if (this.policyEffectiveDate != null) {
      let peffElem: CPMElement = new CPMElement();
      peffElem.Name = "PolicyEffectiveDate";
      peffElem.Value = this.policyEffectiveDate.toString();
      result.push(peffElem);
    }

    if (this.policyExpirationDate != null) {
      let pexpElem: CPMElement = new CPMElement();
      pexpElem.Name = "PolicyExpirationDate";
      pexpElem.Value = this.policyExpirationDate.toString();
      result.push(pexpElem);
    }

    // Policy State
    if (this.selectedState != "") {
      let stElem: CPMElement = new CPMElement();
      stElem.Name = "PolicyState";
      stElem.Value = this.selectedState;
      result.push(stElem);
    }

    // LOB as Product
    if (this.selectedLOB != "") {
      let prdctElem: CPMElement = new CPMElement();
      prdctElem.Name = "Product";
      prdctElem.Value = this.selectedLOB;
      result.push(prdctElem);
    }

    // Writing Company
    if (this.selectedPaper != "") {
      let wccElem: CPMElement = new CPMElement();
      wccElem.Name = "WritingCompanyCode";
      wccElem.Value = this.selectedPaper;
      result.push(wccElem);

      let wcnElem: CPMElement = new CPMElement();
      wcnElem.Name = "WritingCompanyName";
      for (var i = 0; i < this.paperRuleList.length; i++) {
        var paperRule = this.paperRuleList[i];
        let wcCode = String(paperRule["Code"]);
        if (wcCode.indexOf(this.selectedPaper) != -1)
          wcnElem.Value = String(paperRule["Description"]);
      }
      console.log(wcnElem);
      console.log(wccElem);
      result.push(wcnElem);
    }

    return result;
  }

  setCpmForms(rulesForms: RuleEngineForm[]): CPMForm[] {
    var cpmForms: CPMForm[] = [];
    rulesForms.forEach((f) => {
      let cpmForm: CPMForm = new CPMForm();
      cpmForm.InternalFormNumber = f.InternalFormNumber;
      cpmForm.LineOfBusinessID = "1564"; //change this
      cpmForm.FormName = f.FormName;
      cpmForm.Edition = "0";
      cpmForm.ExternalFormNumber = f.ExternalFormNumber;
      cpmForm.SortOrder = f.SortOrder;
      cpmForm.AttachmentReason = "Mandatory";
      cpmForm.FormFillIns = null;
      cpmForms.push(cpmForm);
    });
    return cpmForms;
  }

  //Opens a dialog window to view and manage the selected forms list
  viewSelected() {
    const modalRef = this._modal.open(SelectedFormsComponent, {
      windowClass: "transactions-modal",
    });
    modalRef.componentInstance.formsList = this.checkedFormsFulls;
    modalRef.result.then((result: RuleEngineForm[]) => {
      this.uncheckAll();
      this.setSelectedForms(result.map((a) => a.InternalFormNumber));
    });
  }

  exportInfo() {
    var exportingForms: PolicyFormExport[] = [];
    const replacer = (key, value) => (value === null ? "" : value);
    const header = [
      "InternalFormNumber",
      "FormName",
      "EffectiveDate",
      "ExpirationDate",
      "SubmissionExpirationDate",
      "FormType",
      "In Multiple Coverages",
      "Mandatory",
    ];
    let csv: string[] = [];
    csv.push(header.join(","));
    for (var form of this.selectedCovForms) {
      console.log(form);
      let exForm = new PolicyFormExport();
      exForm.InternalFormNumber = form.InternalFormNumber;
      //Cleaning up special chars and wrapping in quotes to handle commas. Can probably simplify this but I'm pretty tired today
      exForm.FormName = form.FormName.toString().replaceAll('"', "");
      exForm.FormName = exForm.FormName.replace("/,/g", "_");
      exForm.FormName = exForm.FormName.replace(/(\r\n|\n|\r)/gm, "");
      exForm.FormName = `"` + exForm.FormName + `"`;
      exForm.EffectiveDate = new Date(form.EffectiveDate * 1000)
        .toISOString()
        .slice(0, 10);
      if (form.ExpirationDate !== null) {
        exForm.ExpirationDate = new Date(form.ExpirationDate * 1000)
          .toISOString()
          .slice(0, 10);
      }
      if (form.SubmissionExpirationDate !== null) {
        exForm.SubmissionExpirationDate = new Date(
          form.SubmissionExpirationDate * 1000
        )
          .toISOString()
          .slice(0, 10);
      }
      exForm.FormType = form.FormType;
      exForm.PriorInternalFormNumber = form.PriorInternalFormNumber;
      exForm.FrequentlyUsed = form.FrequentlyUsed;
      exForm.MultipleLOBs = form.MultiLOBIndicator;
      exForm.Mandatory = form.Mandatory;
      csv.push(
        exForm.InternalFormNumber +
          "," +
          exForm.FormName +
          "," +
          exForm.EffectiveDate +
          "," +
          exForm.ExpirationDate +
          "," +
          exForm.SubmissionExpirationDate +
          "," +
          exForm.FormType +
          "," +
          exForm.MultipleLOBs.toString() +
          "," +
          exForm.Mandatory.toString()
      );
    }
    let csvArray = csv.join("\r\n");
    var blob = new Blob([csvArray], { type: "text/csv" });
    saveAs(blob, "export.csv");
  }
}

