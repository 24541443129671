//Angular Imports
import { Component, HostBinding } from "@angular/core";

//Third Party Imports
import { GridOptions } from "ag-grid-community";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";

//Internal Imports
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
  SharedService,
  DateTimePipe,
  AuthorizationService,
} from "../../../framework";
import { PackageService } from "../../services";
import { StyledComponent } from "../admin-package-component/styledComponent";
import { AdminForm, Rule } from "../../../form-page";
import { AdminPublishSaveComponent } from "../admin-publish-save";
import { RuleEngineForm } from "../../../form-page/models/ruleEngineForm.model";
import { isNullOrUndefined } from "util";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

/**
 * Package Component
 */
@Component({
  selector: "admin-package",
  templateUrl: "./admin-package.component.html",
  styleUrls: ["./admin-package.component.scss"],
})
export class AdminPackageComponent extends ApplicationInsightsBaseComponent {
  //Public Variables
  /**
   * Host binding for width
   */
  @HostBinding("style.width") width: string;
  /**
   * selectedLOB
   */
  public selectedLOB: string;
  /**
   * lineOfBusiness
   */
  lineOfBusiness: any[];
  /**
   * formsGridOptions
   */
  public formsGridOptions: GridOptions;

  public currentFormsRules: RuleEngineForm[];

  public selectedFormsRules: RuleEngineForm[];

  public pinnedTopRowData;

  public interalFormNumber: string;
  public selectedRowCount: number = 0;
  /**
   * initialRowDataLoad$
   */
  initialRowDataLoad$;
  /**
   * Constructor
   * @ignore
   */
  constructor(
    private _appInsightsService: AppInsightsService,
    private _modal: NgbModal,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private authService: AuthorizationService,
    private packageService: PackageService,
    private dateTimePipe: DateTimePipe
  ) {
    super(_appInsightsService);
    this.width = "100%";
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {

    const appInsights = new ApplicationInsights({
      config: {
        connectionString: window.sessionStorage.getItem("AIKey")
        /* ...Other Configuration Options... */
      }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView({
      name: 'fast/createPackages',
      uri: location.pathname + location.search
    });

    this.selectedFormsRules = [];
    this.currentFormsRules = [];
    this.getMasterList();
    this.configureGrid();
  }

  /**
   * method is called when we change lob drop down and we need to fetch forms according to that
   */
  public onBusinessPolicyChange(selectedvalue) {
    this.getPolicyFormRuleList(selectedvalue.Name);
  }
  onLOBChange(selectedvalue) {
    this.selectedFormsRules = [];
    this.getPolicyFormRuleList(selectedvalue.Name);
  }
  /**
   *  this method return if that perticluar rule is configured to rule
   */
  private getBusinessPolicyName(params: any): string {
    let businessPolicyName: string = "";
    let currentLOB = this.lineOfBusiness.find(
      (bp) => bp.Name == params.data.LineOfBusiness
    );

    if (currentLOB != null) {
      businessPolicyName = currentLOB.Description;
    }
    return businessPolicyName;
  }
  /**
   *  this method is called when we click on rule row
   */
  private onRuleRowClicked(e: any): void {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");

      switch (actionType) {
        case "Edit":
        //return this.onSelectDeselectForm(data, e.event.target.checked);
      }
    }
  }

  /**
   * we do not want to call this custom event, instead onSelectionChanged will be called when checkbox is selected
   */
  private onSelectDeselectForm(event: any, isSelected: boolean) {
    //var formRuleIndex = _.findIndex(this.currentFormsRules, { FormId: event.FormId });
    //if (isSelected) {
    //  this.selectedFormsRules.push(event);
    //  if (formRuleIndex != null && formRuleIndex >= 0) {
    //    this.currentFormsRules[formRuleIndex].IsSelected = true;
    //  }
    //}
    //else {
    //  var index = _.findIndex(this.selectedFormsRules, { FormId: event.FormId });
    //  if (index != null && index >= 0) {
    //    this.selectedFormsRules.splice(index, 1);
    //  }
    //  if (formRuleIndex != null && formRuleIndex >= 0) {
    //    this.currentFormsRules[formRuleIndex].IsSelected = false;
    //  }
    //}
  }

  /**
   *  configure forms grid
   */
  //12-3-24 Christian: domLayout needs to be set to autoheight or else users run out of space on the grid when selecting a lot of forms, please do not modify this unless addressing the grid height issue
  private configureGrid(): void {
    this.initialRowDataLoad$ = [];
    this.formsGridOptions = <GridOptions>{
      rowSelection: "multiple",
      domLayout: 'autoHeight',
      columnDefs: this.createColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      rowHeight: 35,
      paginationPageSize: 20,
      suppressRowClickSelection: true,
      onGridReady: () => {
        this.formsGridOptions.api.setRowData([]);
        this.formsGridOptions.api.sizeColumnsToFit();
        this.pinnedTopRowData = [];
      },
      onRowClicked: (event) => {
        this.onRuleRowClicked(event);
      },
      onFilterChanged: (event) => {},
      onSortChanged: (event) => {},
      onSelectionChanged: (event) => {
        this.onSelectionChanged(event);
      },

      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    this.selectedRowCount = rowCount; 
    let tempSelectedFormsRules: RuleEngineForm[];
    this.selectedFormsRules = [];
    for (var i = 0; i < rowCount; i++) {
      this.selectedFormsRules.push(event.api.getSelectedNodes()[i].data);
    }

    this.pinnedTopRowData = this.selectedFormsRules;
    // this.formsGridOptions.pinnedTopRowData = this.selectedFormsRules;
  }

  public externalFilterChanged(interFormNumber) {
    if (this.interalFormNumber) {
      let filteredFormRule: RuleEngineForm[];
      filteredFormRule = [];
      _.forEach(this.currentFormsRules, (formRule) => {
        if (
          _.includes(
            formRule.InternalFormNumber.toLowerCase(),
            this.interalFormNumber.toLowerCase()
          )
        ) {
          filteredFormRule.push(formRule);
        }
      });
      this.formsGridOptions.api.setRowData(filteredFormRule);
    } else {
      this.formsGridOptions.api.setRowData(this.currentFormsRules);
    }
  }
  /**
   * configure forms grid columns
   */
  private createColumDef(): any[] {
    return [
      {
        headerName: "Internal Form Number",
        field: "InternalFormNumber",
        resizable: true,
        headerTooltip: 'Internal Form Number',
        filter: "agTextColumnFilter",
        tooltipValueGetter: (params) => params.value,
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: StyledComponent,
              params: {
                style: { "font-weight": "bold", color: "blue" }
              }
            };
          }
        }
      },
      {
        headerName: "Form Name",
        field: "FormName",
        resizable: true,
        tooltipValueGetter: (params) => params.value,
        headerTooltip: 'Form Name',
        filter: "agTextColumnFilter",
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: StyledComponent,
              params: {
                style: { "font-weight": "bold", color: "blue" }
              }
            };
          }
        }
      },
      {
        headerName: "Effective Date",
        field: "EffectiveDate",
        width: 80,
        sortable: true,
        tooltipValueGetter: (params) => params.value,
        headerTooltip: 'Effective Date',
        filter: "dateFilterComponent",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(params.value);
        },
      },
      {
        headerName: "Expiration Date",
        field: "ExpirationDate",
        width: 100,
        sortable: true,
        tooltipValueGetter: (params) => params.value,
        headerTooltip: 'Expiration Date',
        filter: "dateFilterComponent",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(params.value);
        },
      },
      {
        headerName: "Submission Exp Date",
        field: "SubmissionExpirationDate",
        tooltipValueGetter: (params) => params.value,
        headerTooltip: 'Submission Exp Date',
        sortable: true,
        width: 100,
        filter: "dateFilterComponent",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(params.value);
        },
      },
      {
        headerName: "Modified Date",
        field: "ModifiedDate",
        sortable: true,
        tooltipValueGetter: (params) => params.value,
        headerTooltip: 'Modified Date',
        filter: "dateFilterComponent",
        sort: "desc",
        cellRenderer: (params) => {
          return this.dateTimePipe.transform(
            params.value,
            "string",
            "MM/DD/YYYY h:mm A"
          );
        },
      },
      {
        headerName: "Modified By",
        field: "ModifiedByName",
        resizable: true,
        filter: "agTextColumnFilter",
        tooltipValueGetter: (params) => params.value,
        headerTooltip: 'Modified By',
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: StyledComponent,
              params: {
                style: { "font-weight": "bold", color: "blue" }
              }
            };
          }
        }
      },
      //{
      //  headerName: 'LOB',
      //  cellRenderer: params => {
      //    let businessPolicyName = this.getBusinessPolicyName(params);
      //    return businessPolicyName;
      //  }
      //},
      {
        headerName: "Select",
        field: "IsSelected",
        filter: "agTextColumnFilter",
        tooltipValueGetter: (params) => params.value,
        headerTooltip: 'Select',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        width: 75,
        //cellRenderer: params => {
        //  return `<input type='checkbox' data-action-type="Edit"  ${params.value ? 'checked' : ''} />`;
        //}
      },
    ];
  }
  /**
   *this method is calledto fect master data eg.Lob list etc
   */
  private getMasterList() {
    this.sharedService.getMasterData().subscribe((el) => {
      this.lineOfBusiness = el.LineOfBusinessList;
      if (this.authService.MPLUserOnly()) {
        this.lineOfBusiness = this.lineOfBusiness.filter(
          (x) => x.Name == "NFB||DF"
        );
      }
      this.selectedLOB = this.lineOfBusiness[0].Name;
      this.getPolicyFormRuleList(this.selectedLOB);
    });
  }
  /**
   * NgOnDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  /**
   *  to get forms master list
   */
  public getPolicyFormRuleList(lobName: string) {
    /*Check if lob name contains '&' symbol then encode with %26.This will fix value truncating issue with querstring parameter */
    if (lobName.indexOf("&") > -1) {
      lobName = lobName.replace("&", "%26");
    }
    //CR in new data structure need to get the LOB attribute set for forms, using filter because find isn't supported by current compiler
    this.packageService
      .getAdminFormsForLOB(lobName)
      .subscribe((res: AdminForm[]) => {
        //console.log(res);
        var adminForms = res;
        this.currentFormsRules = [];
        //Take attribute set of current form and put all relevant info into Rule Engine Form data structure for display and deploying
        for (var item of adminForms) {
          let ruleEngineForm = new RuleEngineForm();
          var lobSet = item.AttributeSets.find(
            (element) => element.Scope == lobName
          );
          if (!isNullOrUndefined(lobSet)) {
            ruleEngineForm.AdminFormId = item.id;
            ruleEngineForm.InternalFormNumber = item.InternalFormNumber;
            ruleEngineForm.ExternalFormNumber = item.ExternalFormNumber;
            ruleEngineForm.MultiLOBIndicator = item.MultiLOBIndicator;
            ruleEngineForm.CreationDate = item.CreationDate;
            ruleEngineForm.CreatedByName = item.CreatedByName;
            ruleEngineForm.ModifiedDate = item.ModifiedDate;
            ruleEngineForm.ModifiedByName = item.ModifiedByName;
            ruleEngineForm.ImportFromMOL = item.ImportFromMOL;
            ruleEngineForm.MolDeptId = item.MolDeptId;
            ruleEngineForm.MolId = item.MolId;
            ruleEngineForm.MolNumber = item.MolNumber;
            ruleEngineForm.MolEffectiveDate = item.MolEffectiveDate;
            ruleEngineForm.MolEditionDate = item.MolEditionDate;
            ruleEngineForm.PriorInternalFormNumber =
              item.PriorInternalFormNumber;
            ruleEngineForm.LOB = lobName;
            if (
              !isNullOrUndefined(
                item.RuleSetups.find((set) => set.LOB.indexOf(lobName) != -1)
              )
            ) {
              ruleEngineForm.RuleSet = item.RuleSetups.find(
                (set) => set.LOB.indexOf(lobName) != -1
              ).RuleSet;
            }

            //In future state all of these value should be present, for now check if undefined before setting
            if (!isNullOrUndefined(lobSet.DisplayOnly)) {
              ruleEngineForm.DisplayOnly = lobSet.DisplayOnly;
            }
            ruleEngineForm.PolicyLevel = lobSet.PolicyLevel;
            ruleEngineForm.FormName = lobSet.FormName;
            ruleEngineForm.SortOrder = lobSet.SortOrder;
            ruleEngineForm.FormType = lobSet.FormType;
            ruleEngineForm.EffectiveDate = lobSet.EffectiveDate;
            ruleEngineForm.ExpirationDate = lobSet.ExpirationDate;
            ruleEngineForm.SubmissionExpirationDate =
              lobSet.SubmissionExpirationDate;
            ruleEngineForm.FrequentlyUsed = lobSet.FrequentlyUsed;

            this.currentFormsRules.push(ruleEngineForm);
          }
        }
        console.log(this.currentFormsRules);
        this.formsGridOptions.api.setRowData(this.currentFormsRules);
      });
  }

  //Added temprorairly to fix the error
  updatePublish() {
    if (this.selectedFormsRules && this.selectedFormsRules.length != 0) {
      const modalRef = this._modal.open(AdminPublishSaveComponent, {
        backdrop: "static",
      });

      modalRef.componentInstance.selectedFormsRules = this.selectedFormsRules;
      modalRef.componentInstance.selectedLob = this.selectedLOB;
      modalRef.result.then((result: any) => {
        if (result) {
          _.forEach(this.currentFormsRules, (formRule) => {
            // formRule.IsSelected = false;
          });
          this.formsGridOptions.api.setRowData(this.currentFormsRules);
          this.selectedFormsRules = [];
          this.interalFormNumber = "";
        }
      });
    }
  }
}
