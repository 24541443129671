
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { map } from 'rxjs';
import { EnvironmentSpecifics } from './framework/models/environment-specific.model';

@Injectable({ providedIn: 'root' })
export class AppInitService {
  public _environmentDetails: EnvironmentSpecifics;
  private http: HttpClient;


  constructor(private httpBackend: HttpBackend) {
    this.http = new HttpClient(this.httpBackend)
  }

  Init() {


    return new Promise((resolve, reject) => {
      this.http.get<EnvironmentSpecifics>('appsettings.json').subscribe
        (res => {
          console.log(res);
          this._environmentDetails = <EnvironmentSpecifics>res;
          window.sessionStorage.setItem("clientID", res.AZUREAD_CLIENTID);
          window.sessionStorage.setItem("replyUrl", res.AZUREAD_REPLY_URL);
          window.sessionStorage.setItem("AIKey", res.APP_INSIGHTS_STRING);
          resolve(this._environmentDetails);
        },
          error => { console.log(error); reject(true); });
    });

  }
}
