<div
  class="component-container"
  style="background-color: white"
  id="grid_container"
>
  <div class="component-content-view pr-0">
    <div class="container-fluid">
      <div id="policy-form-container" class="row" style="margin-top: 5px">
        <div class="col-md-3 p-0" style="max-width: calc(20%)">
          <div class="filter-panel">
            <h5>Filters</h5>
          </div>
        </div>
        <div
          class="col-md-10"
          style="padding: 0 5px; max-width: calc(80%)"
        >
          <!-- Search Bar Section -->
          <div
            @fadeInUp
            class="tw-border bg-foreground tw-py-2 ltr:tw-pl-6 rtl:tw-pr-6 ltr:tw-pr-0 rtl:tw-pl-0 text-secondary tw-max-w-xl tw-mx-auto tw-shadow-xl tw-flex tw-items-center"
            style="
             
              box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
              font-size: 16px;
              max-width: 63rem;
              margin-bottom: 13px;
              margin-left: 3px;
              height: 35px;
              background-color: white;
              margin-top: 5px;
            "
          >
            <mat-icon class="tw-flex-none" svgIcon="mat:search"></mat-icon>
            <input
              class="tw-border-0 ltr:tw-ml-2 rtl:tw-mr-4 placeholder:text-secondary subheading-2 tw-outline-none tw-font-medium tw-w-0 bg-foreground tw-flex-auto"
              type="text"
              placeholder="Enter search form"
              aria-label="Number"
              (input)="getSuggestions($event)"
              (keydown.enter)="externalFilterChanged(searchInput, 'search')"
              [(ngModel)]="searchInput"
              [matAutocomplete]="auto"
              style="font: 17px var(--font); color: #424242"
            />

            <!-- Autocomplete Suggestions -->
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="onOptionSelected($event.option)"
            >
              <mat-option
                *ngFor="let suggestion of suggestedForms"
                [value]="{ name: suggestion.name }"
              >
                {{ suggestion.name }}
              </mat-option>
            </mat-autocomplete>

            <button
              type="button"
              (click)="externalFilterChanged(searchInput, 'search')"
              style="background-color: #009aa6; margin-right: 2px"
              mat-raised-button
            >
              Search
            </button>
            <button
              type="button"
              style="background-color: #818284"
              (click)="resetSearch()"
              mat-raised-button
            >
              Reset
            </button>
          </div>

          <!-- Grid Section -->
          <div id="grid-container" class="ag-grid-container">
            <ag-grid-angular
              class=" ag-theme-alpine ag-header-viewport"
              [rowHeight]="50"
              [gridOptions]="formsGridOptions"
              [pagination]="true"
              style="
                height: calc(100vh - 126px) !important;
                overflow-x: hidden !important;
              "
            >
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
