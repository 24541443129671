//Angular Imports 
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

//Third Party Imports
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//Internal Imports 
import { } from '../../../framework';

import 'rxjs';
import { RuleEngineLog } from '../../../diagnostics/testSuites/execution/models';
import { ExecutionService } from '../../../diagnostics/testSuites/execution/services/execution.service';
import { TestCaseDetail } from '../../../diagnostics/testSuites/execution/components/test-case-detail/test-case-detail.component';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';


/** 
 * Forms Component
*/
@Component({
  selector: 'app-log-search',
  templateUrl: './log-search.component.html',
  styleUrls: ['./log-search.component.scss']
})

export class LogSearch {

  resultsGridOptions: GridOptions;
  public dealNumber: string;
  logResults: RuleEngineLog[];
  selectedResult: RuleEngineLog;

  readonly appInsights = new ApplicationInsights({
    config: {
      connectionString: window.sessionStorage.getItem("AIKey")
      /* ...Other Configuration Options... */
    }
  });

  /** 
   * Constructor
   * @ignore
  */
  constructor(public activeModal: NgbActiveModal,
    private executionService: ExecutionService,
    private _modal: NgbModal,
    private toastr: ToastrService) {

  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
  */
  ngOnInit(): void {
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView({
      name: 'fast/log-search',
      uri: location.pathname + location.search
    });
    this.configureResultsGrid();
  }

  /**
   * NgOnDestroy
   * @ignore
  */
  ngOnDestroy(): void {

  }

  searchLog(): void {
    this.executionService.getLogsByDealNumber(this.dealNumber).subscribe((res: RuleEngineLog[]) => {
      this.logResults = res;
      this.resultsGridOptions.api.setRowData(this.logResults);
    }, error => { this.toastr.error('Error occurred while fetching Logs.'); }
    );
  }

  selectLog(): void {
    this.activeModal.close(this.selectedResult);
  }

  createTestCase(): void {
    //const modalRef = this._modal.open(TestCaseDetail, { backdrop: 'static', size: 'lg' });
    const modalRef = this._modal.open(TestCaseDetail, { windowClass: 'test-case-detail-modal' });
    modalRef.componentInstance.logItem = this.selectedResult;
    modalRef.componentInstance.actionType = "Add";
  }

  private onResultsRowClick(event: any): void {
    if (event.event.target !== undefined) {
      this.selectedResult = event.data;
    }
  }

  private configureResultsGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.resultsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createResultsColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 10,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.resultsGridOptions.api.setRowData([]);
        //this.resultsGridOptions.api.setRowData(this.allResults);
        this.resultsGridOptions.api.sizeColumnsToFit();
        //this.resultsGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        this.onResultsRowClick(event);
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        //console.log(event);
      }
    };

  }

  private createResultsColumDef(): any[] {
    return [
      {
        headerName: 'Timestamp', field: 'Timestamp', resizable: true,sort: 'desc', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          let newDateA = new Date(valueA).getTime();
          let newDateB = new Date(valueB).getTime();
          return newDateA - newDateB;
        },
        cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleString() : '';
        }
      },
      { headerName: 'Environment', resizable: true, field: 'Environment', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Calling System', resizable: true, field: 'CallingSystem', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Action Type', resizable: true, field: 'ActionType', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Deal Number', resizable: true, field: 'SubmissionNumber', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Message', resizable: true, field: 'Message', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
    ]
  }

}
