//Angular Imports 
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";
import { SelectableItem } from "../../../framework/models/selectable-item.model";
import { QueueLog } from "../../../ghostPortal/queue/models/queueLog";
import { IssuanceLog, TestHarnessLogWithEnv } from "../models/diagnosticLog";
import { EnvironmentService } from '../../../framework/services';
import { RuleEngineLog } from "../../testSuites/execution/models/rule-engine-log.model";

/**
  * Policy Forms Provider
*/
@Injectable({
  providedIn: 'root'
})
export class DiagnosticsMainService {

  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient,
      private environmentService: EnvironmentService  ) {
  }



  /**
  * Get form by internal form number
  * @param policyForm
  */
  getLogsByDealNumber(dealNumber: string): Observable<RuleEngineLog[]> {
    let url = `api/RuleEngineLog?dealNumber=` + dealNumber;
    return this.http.get<RuleEngineLog[]>(url).pipe(
      map(res => res)
    );
  }
  searchExtensionLogs(env: string, dealNumber: string): Observable<IssuanceLog[]> {
    let url = `https://extension-${env.toLowerCase()}.azurewebsites.net/api/logs/${dealNumber}`;
    if (env == "PROD")
      url = `https://mna-use2-prod-fd-gde-01.azurefd.net/api/logs/${dealNumber}`;
    return this.http.get<any>(url).pipe(map(res => res));
  }

  getQueueStatus(transactionId: string): Observable<any> {
    var url = `https://mkl-doc-queue-dev1.azurewebsites.net/api/storage/search?dealNumber=${transactionId}`;
    return this.http.get<any>(url).pipe(map(res => res));
  }

  searchLogs(dealNumber: string, env: string, token: string): Observable<TestHarnessLogWithEnv[]> {
    var gurl = `https://mkl-mgdapi-${env}.azurewebsites.net/api/v2//logrequest/getLogs?dealNumber=${dealNumber}&isPortal=1`;
    if (env.toLowerCase() == 'local') {
      gurl = `http://localhost:63161/api/v1//logrequest/getLogs?dealNumber=${dealNumber}`;
    }
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.get <TestHarnessLogWithEnv[]>(gurl, { headers, responseType: 'json' }).pipe(map(res => res));
  }

  getJwt(env: string) {
    var gurl = `https://mkl-mgdapi-${env.toLowerCase()}.azurewebsites.net/api/v2/document/login?UserName=Specialty&Key=BD5065AF-2A62-4002-B08F-0EB91221FDF1`;
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.get(gurl, { headers, responseType: 'text' }).pipe(map(res => res));
  }

  getAllFormNumbersByLob(lob: string): Observable<any[]> {
    let items: string[] = [];

    let url = `api/Adminform/LOB/${lob}`;
    return this.http.get<any>(url).pipe(
      map(res => res)
    );
  }

  getSelectableCpmElementList(cpmElementId: string): Observable<any[]> {
    let items: SelectableItem[] = [];

    let url = `api/MasterData/SelectableItem/${cpmElementId}`;
    return this.http.get<any>(url).pipe(
      map(res => res)
    );
  }

  getCpmDataForTestCase(cpmString: string): Observable<any> {
    let cpm = { "CpmString": cpmString };
    console.log(cpm);
    let url = `api/TestCase/CPMRuleData`;
    return this.http.post<any>(url, cpm).pipe(
      map(res => res)
    );
  }


}
