//Angular Imports
import { AfterViewInit, Component, ElementRef, HostBinding, ViewChild, enableProdMode } from "@angular/core";
import { formatDate } from "@angular/common";
import { ScrollingModule } from "@angular/cdk/scrolling";
//Third Party Imports
import { CellClickedEvent, GridOptions } from "ag-grid-community";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { Toast, ToastrService } from "ngx-toastr";
import * as xml2js from "xml2js";

//Internal Imports
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
  SharedService,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimeUtcPipe,
  AuthorizationService,
} from "../../../../framework";
import { PolicyForm } from "../../../../../app/ghostPortal/formsList/models/policyForm.model";
import { TestHarnessService } from "../services/testHarness.service";
import { HttpErrorResponse } from "@angular/common/http";
//import { TestHarnessLogs } from './testHarness-logs/testHarness-logs.component';
import { TestHarnessTestCase } from "../models/testHarness-testCase.model";
import { TestHarnessTestCaseComponent } from "./testHarness-testCase";
import { TestHarnessTestGroup } from "../models/testHarness-testGroup.model";
import { TestHarnessResults } from "../models/testHarness-testResult.model";
import { TestHarnessLog } from "../models/testHarness-log.model";
import { LogSearch, TestHarnessLogs } from "../../../../logs/components";
import { RuleEngineLog, RuleEngineResult } from "../../execution";
import { TestCaseError } from "./testCaseError/testCaseError.component";
import { TestHarnessCallback } from "../models/testHarness-testCallback";
import { isNullOrUndefined } from "util";
import { DocumentInfo } from "../models/testHarness-documentInfo.model";
import { saveAs } from "file-saver";
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

/**
  Main Layout Component
*/
@Component({
  selector: "testHarness",
  templateUrl: "./testHarness.component.html",
  styleUrls: ["./testHarness.component.scss"],
})
export class TestHarnessComponent extends ApplicationInsightsBaseComponent {
  @HostBinding("style.width") width: string;

  public formsGridOptions: GridOptions;
  public selectedFormsGridOptions: GridOptions;
  public testResultsGridOptions: GridOptions;
  public testCaseGridOptions: GridOptions;
  public envList: any[];
  //Separate vars for what is used in the model and what is used in the component because overwriting the main var causes it to dissappear on the screen
  public selectedEnv: string = "";
  public selectedEnvComponent: string;
  public selectedPackage: string;
  public selectedPackageComponent: string;

  //List of package names, when a package is selected the forms that are part of it will be part of policyFormList
  public packageNames: string[] = [];
  public policyFormList: PolicyForm[];
  //Forms selected from the package list to be generated will populate in this list
  public selectedFormList: PolicyForm[] = [];

  public transaction: string;

  //Variables to hold info for the tabs at the bottom of the page
  public customCPM: string;
  public customFileCPM: string = "";
  public showWarning: boolean = false;
  public fileContent:string | ArrayBuffer | null = '';

  public APIRequest: string;
  public APIResponse: string;
  public Performance: string = "";
  public Errors: string;
  public CheckForms: string;

  lineOfBusinessList: DocumentInfo[] = [];

  //Active tab name, controls visisble elements on html
  public activeSection: string = "Test Forms";

  //Test case variables
  public canCreateTest: boolean = false;
  public logsErr: boolean = false;
  public testCase: TestHarnessTestCase = new TestHarnessTestCase();
  public testCases: TestHarnessTestCase[] = [];
  public testGroups: TestHarnessTestGroup[] = [];
  public testResults: TestHarnessResults[] = [];
  public selectedTestGroup: string;
  //Values from processed CPM
  public actionType: string;
  public timestamp: string;
  public dealNumber: string;

  //Check if attachment system is FAST
  public isFAST: boolean;
  //GDF Error handling
  public gdfError: boolean = false;
  public gdfErrorText: string = "";
  //GDF Forms.txt list
  public formsTxt: string[] = [];
  // Used for GDF; don't show log info unless log selected after search
  public logSelected: boolean = false;
  public isReadOnly: boolean = false;

  public showTestCases = false;

  selectedIndex = 0;
  initialRowDataLoad$;

  public existingFormsListInput: string = "";
  isDropdownOpen: boolean;

  readonly appInsightsgdf = new ApplicationInsights({
    config: {
      connectionString: window.sessionStorage.getItem("AIKey")
      /* ...Other Configuration Options... */
    }
  });

  /**
   * Constructor
   * @ignore
   */
  constructor(
    private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private harnessService: TestHarnessService,
    private _modal: NgbModal,
    private sharedService: SharedService,
    private authService: AuthorizationService,
    private dateTimePipe: DateTimeUtcPipe
  ) {
    super(_appInsightsService);
    this.width = "100%";
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {

    
    this.appInsightsgdf.loadAppInsights();
    this.appInsightsgdf.trackPageView({
      name: 'ghostPortal/testHarness',
      uri: location.pathname + location.search
    });

    this.envList = [
      { Name: "DEV1", ID: 0 },
      { Name: "DEV2", ID: 1 },
      { Name: "QA1", ID: 2 },
      { Name: "QA2", ID: 3 },
      { Name: "UAT1", ID: 4 },
      { Name: "UAT2", ID: 5 },
      { Name: "PXUAT", ID: 8 },
      { Name: "MIRROR", ID: 6 },
      { Name: "FIT", ID: 7 },
      { Name: "PROD", ID: 9 },
      { Name: "FAILOVER", ID: 10 },
      { Name: "LOCAL", ID:11}
    ];
    this.configureGrid();
    this.configureGrid2();

    this.configureTestResultsGrid();
    this.configureTestCaseGrid();
    //document.getElementById("test_forms_section_btn").focus();
    this.isReadOnly = this.authService.readerOnly();

    enableProdMode();
  }

  /**
   * NgDestroy
   * @ignore
   */
  ngOnDestroy(): void {}


  loadMoreContent() {
    // Logic to load more content into the textarea
  }

  loadTestForms(): void {
    this.activeSection = "Test Forms";
  }
  loadRunSavedTests(): void {
    this.activeSection = "Run Saved Tests";
    this.harnessService.getTestGroups().subscribe((res) => {
      this.testGroups = res;
    });
    this.harnessService.getTestCases().subscribe((res) => {
      this.testCases = res;
     // this.exportInfoToCSV();
    });
    //this.testCases = this.harnessService.getTestCases();
  }
  loadManageTests(): void {
    this.activeSection = "Manage Tests";
  }

  selectTab(index: number) {
    this.selectedIndex = index;
  }
  //When CPM Input box is edited save value to global varaible
  onKey(event: any) {
    this.customCPM = event.target.value;
    this.canCreateTest = false;
  }
  onTransKey(event: any) {
    this.transaction = event.target.value;
  }

  //Call get packages and refresh package list for selected env
  onEnvChange(selectedValue) {
    this.selectedEnvComponent = selectedValue.Name;
    this.logsErr = false;
    this.harnessService.getJwt(this.selectedEnvComponent).subscribe((t) => {
      var env =
        this.selectedEnvComponent == "FAILOVER"
          ? "PROD"
          : this.selectedEnvComponent;
      this.harnessService
        .getDocumentInfo(
          `{ 'Documents': null, 'Data': '', 'CallingSystem': 'GhostPortal', 'Environment': '${env}', 'TransactionId': null, 'CombineDocuments': false, 'IsPreview': false, 'DocumentDomain': null, 'DocumentFormat': null }`,
          this.selectedEnvComponent,
          t
        )
        .subscribe((res) => {
          this.lineOfBusinessList = res;
          this.packageNames = [... new Set(this.lineOfBusinessList.map(x => x.PackageName))];
          console.log(this.packageNames);
        });
      this.canCreateTest = false;
    });
  }

  private getCache() {
    if (this.selectedEnvComponent == null) {
      this.toastr.error("Environment required.", "Missing Info", {
        positionClass: "toast-bottom-right",
      });
    } else {
      this.APIResponse = "";
      this.Errors = "";
      let startFrom = performance.now();
      this.harnessService.getJwt(this.selectedEnvComponent).subscribe((t) => {
        this.harnessService
          .getCache(this.selectedEnvComponent.toLowerCase(), t)
          .subscribe(
            (res) => {
              let responseTime = (
                (performance.now() - startFrom) /
                1000
              ).toFixed(4);
              this.Errors = "";
              this.Performance +=
                `${this.selectedEnvComponent}, GetCache, ` +
                responseTime +
                " seconds\n";
              this.selectTab(4);
              let cacheString = JSON.stringify(res);

              var blob = new Blob([cacheString], { type: "text/plain" });
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(
                  blob,
                  `${this.selectedEnvComponent.toUpperCase()}_GhostDraftCache.txt`
                );
              } else {
                var downloadURL = window.URL.createObjectURL(blob);
                var link = document.createElement("a");
                link.href = downloadURL;
                link.download = `${this.selectedEnvComponent.toUpperCase()}_GhostDraftCache.txt`;
                link.click();
              }
            },
            (err: HttpErrorResponse) => {
              let responseTime = (
                (performance.now() - startFrom) /
                1000
              ).toFixed(4);
              this.Performance +=
                `${this.selectedEnvComponent}, GetCache, ` +
                responseTime +
                " seconds\n";
              console.log(err);
              this.Errors = err.message + "\n" + err.error;
              this.selectTab(1);
            }
          );
      });
    }
  }

  //Wipe current package list and re populate with list of forms from selected package
  onPackageChange(envPackage) {
    this.selectedPackageComponent = envPackage;
    this.policyFormList = [];
    var index = this.packageNames.indexOf(envPackage);
    var temp = this.lineOfBusinessList.filter(x => x.PackageName == envPackage);
    
    /*var temp = this.lineOfBusinessList[index].Forms;

    for (var i = 0; i < temp.length; i++) {
      var tempForm = new PolicyForm();
      tempForm.FormName = temp[i];
      tempForm.PackageName = envPackage.Name;
      this.policyFormList[i] = tempForm;
    }*/
    this.formsGridOptions.api.setRowData(temp);
   // this.setRowData();
  }

  uploadLargeCPM(){
    
  }

  resetLargeCPM(){
    this.customFileCPM = "";
  }

  onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData?.getData('text') || '';
    if (pastedText.length > 200000) {
      event.preventDefault();
      confirm("CPM is too large to paste, please load using 'Upload CPM as File' button");
    } else {
      this.showWarning = false;
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        //this.fileContent = reader.result;
        this.customFileCPM = reader.result.toString();
        this.importForms();
      };
      reader.readAsText(file);
    }
  }


  checkExistingForms() {
    if (this.CheckForms && this.selectedEnvComponent) {
      var forms = this.CheckForms.split("\n");
      if (forms.length > 0) {
        console.log(forms);
        var formNames: string = ``;
        forms.forEach((form) => {
          formNames += `"${form}",`;
        });
        //After compiling list of form names remove trailing comma
        formNames = formNames.substr(0, formNames.length - 1);
        var data = `{"Documents": [${formNames}]}`;
        this.APIResponse = "";
        this.Errors = "";
        let startFrom = performance.now();
        this.harnessService
          .checkExistingForms(data, this.selectedEnvComponent)
          .subscribe((res) => {
            let responseTime = ((performance.now() - startFrom) / 1000).toFixed(
              4
            );
            this.Errors = "";
            this.Performance +=
              `${this.selectedEnvComponent}, CheckForms, ` +
              responseTime +
              " seconds\n";
            this.selectTab(4);
            //parse return object as csv

            var keys = Object.keys(res);
            console.log(keys);
            var fileString = "Form Number,Package Name\n";
            keys.forEach((key) => {
              fileString += `${key},${res[key]}\n`;
            });
            let downloadFileName = `${this.selectedEnvComponent}_Existing_Templates.csv`;
            var blob = new Blob([fileString], { type: "text/csv" });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, downloadFileName);
            } else {
              var downloadURL = window.URL.createObjectURL(blob);
              var link = document.createElement("a");
              link.href = downloadURL;
              link.download = downloadFileName;
              link.click();
            }
          });
      } else {
        this.toastr.error(
          "No forms Present",
          "could not determine forms list",
          { positionClass: "toast-bottom-right" }
        );
      }
    } else {
      this.toastr.error(
        "Environment and/or Forms list empty",
        "Please select env and forms",
        { positionClass: "toast-bottom-right" }
      );
    }
  }
  //Search logs and populate info based on selected log, differentiate based on the selected system (Doc Gen or doc Attach)
  searchLogs(): void {
    //If coming from document attachment system, populate log details but do not generate form list as it shouldn't exist anyways for doc attachment
    if (this.isFAST) {
      const modalRef = this._modal.open(LogSearch, {
        backdrop: "static",
        size: "xl",
      });

      modalRef.result.then((result: RuleEngineLog) => {
        if (result) {
          let ruleEngineResults: RuleEngineResult[] = JSON.parse(
            result.RuleEngineResults
          );
          this.customCPM = result.CPM;
          this.dealNumber = result.SubmissionNumber;
          this.actionType = result.ActionType;
          this.timestamp = result.Timestamp;

          let combinedFinalResults: any[] = [];
          for (let r of ruleEngineResults) {
            r.Scenario = "Manual Input";
            combinedFinalResults = combinedFinalResults.concat(r.FinalResults);
          }
        }
      });
    } else {
      //Search GDF logs, selecting a log will populate log details
      const modalRef = this._modal.open(TestHarnessLogs, {
        backdrop: "static",
        size: "lg",
      });
      modalRef.componentInstance.env = this.selectedEnvComponent;
      modalRef.result.then((result: TestHarnessLog) => {
        if (result) {
          this.customCPM = result.XmlData;
          var date = Date.parse(result.TimeStamp);
          this.formsTxt = result.Forms;
          this.timestamp = new Date(date).toString();
          var dealNumber;
          this.actionType = result.ActionType;
          console.log(result);
          if (this.customCPM.startsWith("<")) {
            var parser = new xml2js.Parser();
            //Parse CPM for certain log info
            parser.parseString(this.customCPM, function (err, result) {
              if (result.QuoteMessage != null) {
                dealNumber = String(
                  result.QuoteMessage.Quote[0].QuoteOptions[0].QuoteOption[0]
                    .Policy[0].SubmissionNumber
                );
              } else {
                dealNumber = String(
                  result.PolicyMessage.Policy[0].SubmissionNumber
                );
              }
            });
          } else {
            var s = this.customCPM;
            // preserve newlines, etc - use valid JSON
            s = s
              .replace(/\\n/g, "\\n")
              .replace(/\\'/g, "\\'")
              .replace(/\\"/g, '\\"')
              .replace(/\\&/g, "\\&")
              .replace(/\\r/g, "\\r")
              .replace(/\\t/g, "\\t")
              .replace(/\\b/g, "\\b")
              .replace(/\\f/g, "\\f");
            // remove non-printable and other non-valid JSON chars
            s = s.replace(/[\u0000-\u0019]+/g, "");
            var json = JSON.parse(s.trim());
            console.log(json);
            if (json.QuoteMessage != null) {
              dealNumber =
                json.QuoteMessage.QuoteOptions.QuoteOption[0].Policy
                  .PolicyNumber;
            } else {
              dealNumber = json.PolicyMessage.Policy.PolicyNumber;
            }
          }

          this.dealNumber = dealNumber;
          //Display log box if log has error (hasError is checked on log popup)
          if (result.hasError) {
            this.gdfError = true;
            this.gdfErrorText = result.ErrorMessage;
          } else {
            this.gdfError = false;
            this.gdfErrorText = "";
          }
          this.logSelected = true;
          this.importForms();
        }
      });
    }
  }

  loadLog(result: TestHarnessLog) {
    this.customCPM = result.XmlData;
    var date = Date.parse(result.TimeStamp);
    this.formsTxt = result.Forms;
    this.timestamp = new Date(date).toString();
    var dealNumber;
    this.actionType = result.ActionType;
    if (this.customCPM.startsWith("<")) {
      var parser = new xml2js.Parser();
      //Parse CPM for certain log info
      parser.parseString(this.customCPM, function (err, result) {
        if (result.QuoteMessage != null) {
          dealNumber = String(
            result.QuoteMessage.Quote[0].QuoteOptions[0].QuoteOption[0]
              .Policy[0].SubmissionNumber
          );
        } else {
          dealNumber = String(result.PolicyMessage.Policy[0].SubmissionNumber);
        }
      });
    } else {
      var s = this.customCPM;
      // preserve newlines, etc - use valid JSON
      s = s
        .replace(/\\n/g, "\\n")
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, "\\&")
        .replace(/\\r/g, "\\r")
        .replace(/\\t/g, "\\t")
        .replace(/\\b/g, "\\b")
        .replace(/\\f/g, "\\f");
      // remove non-printable and other non-valid JSON chars
      s = s.replace(/[\u0000-\u0019]+/g, "");
      var json = JSON.parse(s.trim());
      if (json.QuoteMessage != null) {
        dealNumber =
          json.QuoteMessage.QuoteOptions.QuoteOption[0].Policy.PolicyNumber;
      } else {
        dealNumber = json.PolicyMessage.Policy.PolicyNumber;
      }
    }

    this.dealNumber = dealNumber;
    //Display log box if log has error (hasError is checked on log popup)
    if (result.hasError) {
      this.gdfError = true;
      this.gdfErrorText = result.ErrorMessage;
    } else {
      this.gdfError = false;
      this.gdfErrorText = "";
    }
    this.logSelected = true;
    this.importForms();
  }

  clearLogData() {
    this.dealNumber = "";
    this.actionType = "";
    this.timestamp = "";
    this.formsTxt = [];
    this.gdfErrorText = "";
    this.logSelected = false;

    this.selectedFormList = [];
    this.canCreateTest = false;
    this.setSelectedRows();
    this.customCPM = "";
  }
 
  onSelectSystem(event: MatTabChangeEvent) {
    const selectedIndex = event.index;
    if (selectedIndex === 1) {
      this.isFAST = true;
    } else if (selectedIndex === 0) {
      this.isFAST = false;
    }
  }
  createTestCase() {
    const modalRef = this._modal.open(TestHarnessTestCaseComponent, {
      windowClass: "test-case-detail-modal",
    });
    console.log(this.testCase);
    modalRef.componentInstance.testCase = this.testCase;
    modalRef.componentInstance.actionType = "Add";
    modalRef.result.then((result: TestHarnessTestCase) => {
      if (result) {
        this.harnessService.saveTestCase(result).subscribe((res) => {
          console.log(res);
        });
      }
    });
  }

  importForms() {
    if (this.formsTxt.length > 0) {
      var tempFormList: any[] = [];
      for (var i = 0; i < this.formsTxt.length; i++) {
        var form = new PolicyForm();
        form.FormName = this.formsTxt[i];
        form.DocumentName = this.formsTxt[i];
        form.PackageName = "Imported";
        tempFormList.push(form);
      }

      var obj = new PolicyForm();
      let keys = Object.keys(obj);
      let def = keys.reduce((result, key) => {
        result[key] = "";
        return result;
      }, {});
      let result = tempFormList.map((item) => ({ ...def, ...item }));
      this.selectedFormList = result;
      this.canCreateTest = false;
      this.setSelectedRows();

    }
    else {
      //If CPM Input exists initialize temp form list and parser to parse XML to JSON
      var usedCPM = "";

      if(this.customCPM != null && this.customCPM.length > 0){
        usedCPM = this.customCPM;
      }
      else if(this.customFileCPM != null && this.customFileCPM.length > 0){
        usedCPM = this.customFileCPM
      }
      if (usedCPM.length > 0) {
        var tempFormList: any[] = [];
        //Check for JSON CPM vs XML
        if (usedCPM.startsWith("{")) {
          var s = usedCPM;
          // preserve newlines, etc - use valid JSON
          s = s
            .replace(/\\n/g, "\\n")
            .replace(/\\'/g, "\\'")
            .replace(/\\"/g, '\\"')
            .replace(/\\&/g, "\\&")
            .replace(/\\r/g, "\\r")
            .replace(/\\t/g, "\\t")
            .replace(/\\b/g, "\\b")
            .replace(/\\f/g, "\\f");
          // remove non-printable and other non-valid JSON chars
          s = s.replace(/[\u0000-\u0019]+/g, "");
          var json = JSON.parse(s.trim());
          console.log(json);
          var forms: any;
          if (json.QuoteMessage != null) {
            forms = json.QuoteMessage.QuoteOptions.QuoteOption[0].Policy.Form;
          } else {
            forms = json.PolicyMessage.Policy.Form;
          }
          console.log(forms);
          for (var i = 0; i < forms.length; i++) {
            var form = new PolicyForm();
            form.FormName = forms[i].InternalFormNumber;
            form.DocumentName = forms[i].InternalFormNumber;
            form.PackageName = "Imported";
            tempFormList.push(form);
          }
        } else {
          var parser = new xml2js.Parser();
          usedCPM = usedCPM.replace(/\\"/g, '"');
          parser.parseString(usedCPM, function (err, result) {
            if(err){
              console.log(err);
              return;
            }
            var forms: any;
            console.log(usedCPM);
            console.log(result);
            //Pull forms list into array var
            if (result.QuoteMessage != null) {
              forms =
                result.QuoteMessage.Quote[0].QuoteOptions[0].QuoteOption[0]
                  .Policy[0].Forms[0].Form;
            } else {
              forms = result.PolicyMessage.Policy[0].Forms[0].Form;
            }
            //Create form object and populate fields used in grid, then push to temp list
            for (var i = 0; i < forms.length; i++) {
              var form = new PolicyForm();
              form.FormName = forms[i].$.InternalFormNumber;
              form.DocumentName = forms[i].$.InternalFormNumber;
              form.PackageName = "Imported";
              tempFormList.push(form);
            }
          });
        }

        //Create policy form object and iterate through keys to populate all fields with default value (needed to set global list since model does not have default constructor)
        var obj = new PolicyForm();
        let keys = Object.keys(obj);
        let def = keys.reduce((result, key) => {
          result[key] = "";
          return result;
        }, {});
        let result = tempFormList.map((item) => ({ ...def, ...item }));
        this.selectedFormList = result;
        this.canCreateTest = false;
        this.setSelectedRows();
      } else {
        this.toastr.error("Data input required. (CPM/CCM/GBS)", "Missing Info", {
          positionClass: "toast-bottom-right",
        });
        this.selectTab(0);
        return;
      }
    }
    
  }

  //Fetch the local cache used by the MGD API
  fetchGhostDraftCache() {
    this.getCache();
  }

  //Map and assemble list of forms in Selected Forms grid, pulls list of form names out to be injected in API call
  mapAndAssembleMultiple() {
    let errors = [];
    if (this.customCPM == null && this.customFileCPM.length == 0)
      errors.push("Data input required. (CPM/CCM/GBS)");
    if (this.selectedEnvComponent == null) errors.push("Environment required.");
    if (this.selectedFormList.length < 1) errors.push("Forms required.");
    if (errors.length < 1) {
      var formNames: string = ``;
      this.selectedFormList.forEach((form) => {
        formNames += `"${form.DocumentName}",`;
      });
      //After compiling list of form names remove trailing comma
      formNames = formNames.substr(0, formNames.length - 1);
      this.mapAndAssemble(formNames, false);
    } else {
      errors.forEach((e) => {
        this.toastr.error(e, "Missing Info", {
          positionClass: "toast-bottom-right",
        });
      });
    }
  }

  // Get Doc Info for forms in selected forms grid using CPM in xml input tab
  documentInfoMultiple() {
    let errors = [];
    if (this.customCPM == null && this.customFileCPM.length == 0)
      errors.push("Data input required. (CPM/CCM/GBS)");
    if (this.selectedEnvComponent == null) errors.push("Environment required.");
    if (this.selectedFormList.length < 1) errors.push("Forms required.");
    if (errors.length < 1) {
      var formNames: string = ``;
      this.selectedFormList.forEach((form) => {
        formNames += `"${form.DocumentName}",`;
      });
      formNames = formNames.substr(0, formNames.length - 1);
      this.getDocInfo(formNames);
    } else {
      errors.forEach((e) => {
        this.toastr.error(e, "Missing Info", {
          positionClass: "toast-bottom-right",
        });
      });
    }
  }

  regeneratePolicy() {
    if (this.transaction != null && this.selectedEnvComponent != null) {
      let env = this.selectedEnvComponent.toLowerCase();
      this.harnessService.getJwt(env).subscribe((t) => {
        this.harnessService
          .regeneratePolicy(this.transaction, env, t)
          .subscribe(
            (res) => {
              /*
          const byteCharacters = atob(res);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], { type: 'application/pdf' });
          //Need logic specifically for IE because its a special little boy that refuses to handle blob files
          //You can't hurt me anymore IE I know your secrets
          */
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(res, "Ghostportal_Form.pdf");
              } else {
                var downloadURL = window.URL.createObjectURL(res);
                var link = document.createElement("a");
                link.href = downloadURL;
                link.download = "GhostPortal_Form.pdf";
                link.click();
              }
            },
            (err) => {
              this.selectTab(1);
            }
          );
      });
    }
  }

  async runTests() {
    /* this.testResults = [];
    var selectedTestCases = this.testCases.filter(test => test.TestGroups.indexOf(this.selectedTestGroup) !== -1);
    for (var i = 0; i < selectedTestCases.length; i++) {
      var testResult: TestHarnessResults = new TestHarnessResults();
      var testName = selectedTestCases[i].Name;
      //var expectedResult = selectedTestCases[i].ExpectedResults;
      var cpmData = selectedTestCases[i].CPM;
      cpmData = cpmData.replace(/"/g, '\\"');
      var formNames = "";
      selectedTestCases[i].Forms.forEach((form) => {
        formNames += `"${form}",`;
      });
      //After compiling list of form names remove trailing comma
      formNames = formNames.substr(0, formNames.length - 1);
      var data = `{"Documents":[${formNames}],"Data":"${cpmData}","CallingSystem":"GhostPortal","Environment":"${this.selectedEnvComponent}","TransactionId":null,"CombineDocuments":true,"IsPreview":false,"DocumentDomain":null,"DocumentFormat":"PDF"}`;
      let startFrom = new Date().getTime();
      let env = this.selectedEnvComponent.toLowerCase();
      this.harnessService.getJwt(env).subscribe(t => {
         this.harnessService.exportForms(data, false, env, t).toPromise().then(res => {
          let responseTime = formatDate((new Date().getTime() - startFrom), 'mm:ss.sss', 'en-US');
          var result: string = res[0].Content;
          if (result) {
            testResult.Passed = true;
          }
          else {
            testResult.Passed = false;
          }
          testResult.TestCaseName = testName;
          testResult.Runtime = responseTime;
          this.testResults.push(testResult);
        })
          .catch(error => {
            testResult.TestCaseName = testName;
            testResult.Passed = false;
            testResult.Runtime = formatDate((new Date().getTime() - startFrom), 'mm:ss.sss', 'en-US');
            console.log(testResult);
            this.testResults.push(testResult);

          });
      });


    }*/
    this.harnessService
      .runTestGroup(this.selectedTestGroup, this.selectedEnvComponent)
      .subscribe((res) => {
        console.log(res);
        let callback: TestHarnessCallback = res;
        this.testResults = callback.results;
        this.setTestResultRows();
      });
  }

  async runTestCases() {
    let selectedNodes = this.testCaseGridOptions.api.getSelectedNodes();
    let testCases: TestHarnessTestCase[] = selectedNodes.map(
      (node) => node.data
    );
    this.harnessService
      .runSelectedTests(testCases, this.selectedEnvComponent)
      .subscribe((res) => {
        console.log(res);
        let callback: TestHarnessCallback = res;
        this.testResults = callback.results;
        this.setTestResultRows();
      });
  }

  private onTestCaseGridShow() {
    let selectedTestCases: TestHarnessTestCase[] = [];
    this.showTestCases = true;
    this.harnessService.getTestCases().subscribe((res) => {
      let allTestCases: TestHarnessTestCase[] = res;
      console.log(allTestCases);
      console.log(this.selectedTestGroup);
      for (var testCase of allTestCases) {
        if (testCase.TestGroups.indexOf(this.selectedTestGroup) != -1) {
          selectedTestCases.push(testCase);
        }
      }

      this.testCaseGridOptions.api.setRowData(selectedTestCases);
    });
  }

  //Configure grid which will contain forms list for selected package
  private configureGrid(): void {
    this.initialRowDataLoad$ = [];
    this.formsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "normal",
      columnDefs: this.createColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 10,
      rowHeight: 40,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: () => {
        this.formsGridOptions.api.setRowData([]);
        this.formsGridOptions.api.sizeColumnsToFit();
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        this.onRowClicked(event);
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        console.log(event);
      },
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        console.log(event);
      },
    };
  }
  //Configure grid which will contain list of selected forms from first grid
  private configureGrid2(): void {
    this.initialRowDataLoad$ = [];
    this.selectedFormsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "normal",
      columnDefs: this.createColumDef2(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 10,
      rowHeight: 40,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: () => {
        if (this.selectedFormList.length > 0) {
          this.selectedFormsGridOptions.api.setRowData(this.selectedFormList);
        } else {
          this.selectedFormsGridOptions.api.setRowData([]);
        }

        this.selectedFormsGridOptions.api.sizeColumnsToFit();
        if (!isNullOrUndefined(window.history.state.log)) {
          this.loadLog(window.history.state.log);
        }
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        this.selectedRowClicked(event);
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        console.log(event);
      },
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        console.log(event);
      },
    };
  }

  private configureTestResultsGrid(): void {
    this.initialRowDataLoad$ = [];
    this.testResultsGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "normal",
      columnDefs: this.createResultsColDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 10,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: () => {
        this.testResultsGridOptions.api.setRowData([]);
        this.testResultsGridOptions.api.sizeColumnsToFit();
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        this.onResultsRowClicked(event);
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        console.log(event);
      },
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        console.log(event);
      },
    };
  }

  private configureTestCaseGrid(): void {
    this.initialRowDataLoad$ = [];
    this.testCaseGridOptions = <GridOptions>{
      rowSelection: "multiple",
      domLayout: "normal",
      columnDefs: this.createTestCaseColumnDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      enableColResize: true,
      paginationPageSize: 10,
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: () => {
        this.testCaseGridOptions.api.setRowData([]);
        this.testCaseGridOptions.api.sizeColumnsToFit();
        // this.gridOptions.api.softRefreshView();
      },
      onRowClicked: (event) => {
        this.onTestCaseRowClicked(event);
      },
      onFilterChanged: (event) => {
        //Api call to get the filter data
        console.log(event);
      },
      onSortChanged: (event) => {},
      onFilterModified: function (event) {
        console.log(event);
      },
    };
  }

  /**
   * Configure Columns
   */
  private createColumDef(): any[] {
    let result: any[] = [
      {
        headerName: "Form Number",
        field: "DocumentName",
        filter: "agTextColumnFilter",
        headerTooltip: "Form Number", tooltipValueGetter: (params) => params.value, 
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Has Data Capture",
        field: "HasDataCapture",
        headerTooltip: "Has Data Capture", tooltipValueGetter: (params) => params.value, 
        cellRenderer: params => {
          return `<input type='checkbox' ${params.value == true ? 'checked' : ''} disabled/>`;
        }
      },
      {
        headerName: "Actions",
        suppressMenu: true,
        suppressSorting: true,
        headerTooltip: "Actions", tooltipValueGetter: (params) => params.value, 
        width: 75,
        template: `
         <img src="/assets/images/plus-icon.jpg" data-action-type="Add" class="cursor_pointer mrgrgt10" title="Add Form">
         <img src="/assets/images/preview_icon.png" data-action-type="Preview" class="cursor_pointer" title="Preview">
           `,
      },
    ];

    return result;
  }
  private createColumDef2(): any[] {
    let result: any[] = [
      {
        headerName: "Form Number",
        field: "DocumentName",
        filter: "agTextColumnFilter",
        headerTooltip: "Form Number", tooltipValueGetter: (params) => params.value,
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Actions",
        suppressMenu: true,
        suppressSorting: true,
        headerTooltip: "Actions", tooltipValueGetter: (params) => params.value,
        width: 125,
        template: `
         <img src="/assets/images/x_icon.png" data-action-type="Remove" class="cursor_pointer mrgrgt10" title="Remove Form">
         <img src="/assets/images/preview_icon.png" data-action-type="Preview" class="cursor_pointer" title="Preview">
         <img src="/assets/images/assemble_1.png" data-action-type="Assemble" class="cursor_pointer" title="Map and Assemble Single">
           `,
      },
    ];

    return result;
  }

  private createResultsColDef(): any[] {
    let result: any[] = [
      {
        headerName: "Test Case Name",
        field: "TestCaseName",
        filter: "agTextColumnFilter",
        headerTooltip: "Test Case Name", tooltipValueGetter: (params) => params.value,
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      {
        headerName: "Result",
        headerTooltip: "Result", tooltipValueGetter: (params) => params.value,
        cellRenderer: function (params) {
          if (params.data.Result == "Pass") {
            return `<span>
            <a class="transaction-detail-link" data-action-type="Results">Pass - View PDF</a>
          </span>`;
          }
          return "Fail";
        }
        
      },
      {
        headerName: "Runtime",
        headerTooltip: "Runtime", tooltipValueGetter: (params) => params.value,
        field: "Runtime",
      },
      {
        headerName: "Error",
        width: 80,
        suppressMenu: true,
        headerTooltip: "Error", tooltipValueGetter: (params) => params.value,
        suppressSorting: true,
        cellRenderer: function (params) {
          if (params.data.Error != "Pass" && params.data.Error != null) {
            return `<img src="/assets/images/View_Doc.png" data-action-type="Preview" class="cursor_pointer" title="Preview">`;
          }
          return "";
        },
      },
    ];

    return result;
  }

  viewTestPDF(testCaseName: string): any {
    console.log(testCaseName);
  }

  private createTestCaseColumnDef(): any[] {
    let result: any[] = [
      {
        headerName: "",
        checkboxSelection: true,
        field: "RowSelect",
        suppressMenu: true,
        suppressSorting: true,
        width: 20,
        headerTooltip: " ", tooltipValueGetter: (params) => params.value, 
        filter: "booleanFilterComponent",
      },
      {
        headerName: "Test Case Name",
        field: "Name",
        headerTooltip: "Test Case Name ", tooltipValueGetter: (params) => params.value, 
        filter: "agTextColumnFilter",
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
      },
      { headerName: "Description", field: "Description",headerTooltip: "Description", tooltipValueGetter: (params) => params.value, },
      {
        headerName: "View Details",
        width: 70,
        headerTooltip: "Description ", tooltipValueGetter: (params) => params.value, 
        suppressMenu: true,
        suppressSorting: true,
        template: `<img src="/assets/images/View_Doc.png" data-action-type="Preview" class="cursor_pointer mrgrgt10" title="Preview Test Case">`,
      },
    ];

    return result;
  }

  //Forms list grid actions
  private onRowClicked(e) {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Add":
          this.selectedFormList.push(data);
          this.setSelectedRows();
          this.canCreateTest = false;
          break;
        case "Preview":
          this.mapAndAssemble(data.DocumentName, true);
      }
    }
  }

  //Selected forms list grid actions
  private selectedRowClicked(e) {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Remove":
          this.selectedFormList.splice(this.selectedFormList.indexOf(data), 1);
          this.setSelectedRows();
          this.canCreateTest = false;
          break;
        case "Preview":
          this.mapAndAssemble(data.DocumentName, true);
          break;
        case "Assemble":
          this.mapAndAssemble(`"${data.DocumentName}"`, false);
          break;
      }
    }
  }

  private onResultsRowClicked(e) {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Preview":
          const modalRef = this._modal.open(TestCaseError, {
            windowClass: "xl",
          });
          modalRef.componentInstance.errorText = data.Error;
          break;
        case "Results":
          var testCase = this.testCases.filter(x => x.Name == e.data.TestCaseName)[0];
          console.log(testCase);
          //Generate PDF from test case here
          var formNames: string = ``;
          testCase.Forms.forEach((form) => {
            formNames += `"${form}",`;
          });
          //After compiling list of form names remove trailing comma
          formNames = formNames.substr(0, formNames.length - 1);
          this.customCPM = testCase.CPM;
          this.mapAndAssemble(formNames, false);

      }
    }
  }

  private onTestCaseRowClicked(e) {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Preview":
          const modalRef = this._modal.open(TestHarnessTestCaseComponent, {
            windowClass: "test-case-detail-modal",
          });
          modalRef.componentInstance.testCase = e.data;
          modalRef.componentInstance.actionType = "Edit";
          break;
      }
    }
  }

  private setRowData() {
    if (this.policyFormList != null) {
      this.policyFormList.sort((a: any, b: any) => {
        var nameA = a.DocumentName.toLowerCase();
        var nameB = b.DocumentName.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.formsGridOptions.api.setRowData(this.policyFormList);
    }
  }

  private setSelectedRows() {
    if (this.selectedFormList != null) {
      this.selectedFormList.sort((a: any, b: any) => {
        var nameA = a.DocumentName.toLowerCase();
        var nameB = b.DocumentName.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.selectedFormsGridOptions.api.setRowData(this.selectedFormList);
    }
  }

  private setTestResultRows() {
    if (this.testResults != null) {
      this.testResults.sort((a: any, b: any) => {
        var nameA = a.TestCaseName.toLowerCase();
        var nameB = b.TestCaseName.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.testResultsGridOptions.api.setRowData(this.testResults);
    }
  }

  //Logic for calling MGD API and returning PDF document of one or more forms
  //FormNames: list of form names, can be singular or a list of forms
  //isPreview, dictates whether a preview function was called instead of map and assemble
  private mapAndAssemble(formNames: string, isPreview: boolean) {
    //If not a preview call and CPM Input box has data populate data variable to pass to service function
    this.APIResponse = "";
    this.Errors = "";
    var data: string = null;
    var cpmData: string = null;
    var envForBody =
      this.selectedEnvComponent == "FAILOVER"
        ? "PROD"
        : this.selectedEnvComponent;
    if(this.customCPM != null && this.customCPM.length > 0){
      cpmData = this.customCPM;
    }
    else if(this.customFileCPM != null && this.customFileCPM.length > 0){
      cpmData = this.customFileCPM
    }
    if (cpmData != null && cpmData.length > 0 && !isPreview) {
      cpmData = cpmData.replace(/"/g, '\\"');
      if (cpmData.startsWith("{")) {
        cpmData = cpmData.replace(/\\\\"/g, '\\\\\\"')
      }
      data = `{"Documents":[${formNames}],"Data":"${cpmData}","CallingSystem":"GhostPortal","Environment":"${envForBody}","TransactionId":null,"CombineDocuments":true,"IsPreview":false,"DocumentDomain":null,"DocumentFormat":"PDF"}`;
    } else if (isPreview) {
      cpmData = `<PolicyMessage xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xmlns:xsd=\"http://www.w3.org/2001/XMLSchema\" xmlns=\"http://markel.common.policy/2017/1\">
                                                        <MessageID>ED049355-0FBD-43C5-84C5-53CC72D0DA25</MessageID>
                                                        <SourceSystem>38</SourceSystem>
                                                        <SourceSystemName>e2</SourceSystemName>
                                                        <CallingSystem>38</CallingSystem>
                                                        <CallingSystemName>e2</CallingSystemName>
                                                        <TargetSystem>GhostDraft</TargetSystem>
                                                        <TargetSystemName>GhostDraft</TargetSystemName>
                                                        <ActionType>PrepareQuoteDocuments</ActionType>
                                                        <ActionReason xsi:nil=\"true\" />
                                                        <MessageDate>2019-06-20T06:37:27.2870331</MessageDate>
                                                        <ActionEffectiveDate>2019-06-20T00:00:00</ActionEffectiveDate>
                                                        <TransactionPremiumAmount>150937.5</TransactionPremiumAmount>
                                                        <Policy>
                                                        </Policy>
                                                      </PolicyMessage>`;
      cpmData = cpmData.replace(/"/g, '\\"');
      data = `{"Documents":["${formNames}"],"Data":"${cpmData}","CallingSystem":"GhostPortal","Environment":"${envForBody}","TransactionId":null,"DocumentDomain":null,"WithData":false,"IsDraft":true}`;
    } else {
      this.Errors = "Error: CPM Input required on Map and Assemble";
      this.selectTab(1);
      return;
    }
    this.APIRequest = data;
    let startFrom = performance.now();
    let env = this.selectedEnvComponent.toLowerCase();
    this.harnessService.getJwt(env).subscribe((t) => {
      this.harnessService.exportForms(data, isPreview, env, t).subscribe(
        (res) => {
          let responseTime = ((performance.now() - startFrom) / 1000).toFixed(
            4
          );
          this.Errors = "";
          if (isPreview) {
            this.Performance +=
              `${this.selectedEnvComponent}, Preview, ${formNames}, ` +
              responseTime +
              " seconds\n";
          } else {
            this.Performance +=
              `${this.selectedEnvComponent}, MapAndAssemble, ${formNames}, ` +
              responseTime +
              " seconds\n";
          }
          this.APIResponse = "Document generated successfully";
          this.testCase.CPM = cpmData;
          //this.testCase.ExpectedResults = res[0].Content;
          this.testCase.Forms = [];
          this.selectedFormList.forEach((form) => {
            this.testCase.Forms.push(form.DocumentName);
          });
          this.canCreateTest = true;

          const byteCharacters = atob(res[0].Content);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], { type: "application/pdf" });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "Ghostportal_Form.pdf");
          } else {
            var downloadURL = window.URL.createObjectURL(blob);
            if (isPreview) {
              window.open(downloadURL, "_blank");
            } else {
              var link = document.createElement("a");
              link.href = downloadURL;
              link.download = "GhostPortal_Form.pdf";
              link.click();
            }
          }
        },
        (err: HttpErrorResponse) => {
          let responseTime = ((performance.now() - startFrom) / 1000).toFixed(
            4
          );
          if (isPreview) {
            this.Performance +=
              `${this.selectedEnvComponent}, Preview, ${formNames}, ` +
              responseTime +
              " seconds\n";
          } else {
            this.Performance +=
              `${this.selectedEnvComponent}, MapAndAssemble, ${formNames}, ` +
              responseTime +
              " seconds\n";
          }
          console.log(err);
          this.Errors = err.message + "\n" + err.error;
          this.selectTab(1);
        }
      );
    });
  }

  clearAll(): void {
    this.selectedFormList = [];
    this.setSelectedRows();
    this.canCreateTest = false;
  }

  exportInfoToCSV() {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = ["TestGroups", "Name", "Description", "CreatedOn"];
    let temp: TestHarnessTestCase[] = this.testCases;

    for (var tc = 0; tc < temp.length; tc++) {
      temp[tc].CreationDate = new Date(temp[tc].CreationDate * 1000);
      for (var i = 0; i < temp[tc].TestGroups.length; i++) {
        let testGroup = this.testGroups.filter(tg => temp[tc].TestGroups[i] == tg.id);
        if (testGroup.length > 0) {
          temp[tc].TestGroups[i] = testGroup[0].Name;
        }
      }
    }
    let csv = temp.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(','));
    let csvFullString = csv.join('\r\n');

    var file = new File([csvFullString], "FastReport.csv", { type: "text/plain;charset=utf-8" });
    saveAs(file);
  }

  private getDocInfo(formNames: string) {
    this.APIResponse = "";
    this.Errors = "";
    var data: string = null;
    var cpmData: string = null;
    var envForBody =
      this.selectedEnvComponent == "FAILOVER"
        ? "PROD"
        : this.selectedEnvComponent;
    
    if(this.customCPM != null && this.customCPM.length > 0){
      cpmData = this.customCPM;
    }
    else if(this.customFileCPM != null && this.customFileCPM.length > 0){
      cpmData = this.customFileCPM
    }
    if (cpmData != null && cpmData.length > 0) {
      cpmData = cpmData.replace(/"/g, '\\"');
      data = `{"Documents":[${formNames}],"Data":"${cpmData}","CallingSystem":"GhostPortal","Environment":"${envForBody}","TransactionId":null,"CombineDocuments":true,"IsPreview":false,"DocumentDomain":null,"DocumentFormat":"PDF"}`;
    } else {
      this.Errors = "Error: CPM Input required on Map and Assemble";
      this.selectTab(1);
      return;
    }
    this.APIRequest = data;
    let startFrom = performance.now();
    let env = this.selectedEnvComponent.toLowerCase();
    this.harnessService.getJwt(env).subscribe((t) => {
      this.harnessService.documentInfo(data, env, t).subscribe(
        (res) => {
          let responseTime = ((performance.now() - startFrom) / 1000).toFixed(
            4
          );
          this.Errors = "";
          this.Performance +=
            `${this.selectedEnvComponent}, DocInfo, ${formNames}, ` +
            responseTime +
            " seconds\n";
          let docInfoListString = JSON.stringify(res);
          let numDocs = res.length;

          var blob = new Blob([docInfoListString], { type: "text/plain" });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(
              blob,
              `${numDocs}_Docs_DocumentInfo.txt`
            );
          } else {
            var downloadURL = window.URL.createObjectURL(blob);
            var link = document.createElement("a");
            link.href = downloadURL;
            link.download = `${numDocs}_Docs_DocumentInfo.txt`;
            link.click();
          }
        },
        (err: HttpErrorResponse) => {
          let responseTime = ((performance.now() - startFrom) / 1000).toFixed(
            4
          );
          this.Performance +=
            `${this.selectedEnvComponent}, MapAndAssemble, ${formNames}, ` +
            responseTime +
            " seconds\n";
          console.log(err);
          this.Errors = err.message + "\n" + err.error;
          this.selectTab(1);
        }
      );
    });
  }
  selectedTabIndex: number = 0;
  onTabChanged(index: number) {
    this.selectedTabIndex = index;
    switch (index) {
      case 0:
        this.loadTestForms();
        break;
      case 1:
        this.loadRunSavedTests();
        break;
      case 2:
        this.loadManageTests();
        break;
      
      default:
        break;
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
