//Angular Imports
import {
  Component,
  HostBinding,
  TemplateRef,
  ViewChild,
} from "@angular/core";
//Third Party Imports
import { GridOptions } from "ag-grid-community";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
//Internal Imports
import {
  ApplicationInsightsBaseComponent,
  AppInsightsService,
  SharedService,
  DateFilterComponent,
  BooleanFilterComponent,
  DateTimeUtcPipe,
  AuthorizationService,
  UserProfileService,
} from "../../../../framework";
import { FormsListService } from "../../services";
import { ActivatedRoute } from "@angular/router";
import { RuleEngineForm } from "../../../../form-page/models/ruleEngineForm.model";
import { AdminForm, Rule } from "../../../../form-page";
import {TransactionResponse } from "../../models";
import { ProductList } from "../../models/productList.model";
import { FormControl, UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { SearchRequest } from "../../models/search-request.model";
import { SearchResponse } from "../../models/search-response.model";
import { SortObject } from "../../models/sort-object.model"
import { SortOrder } from "../../models/SortOrder";
import { MatOption } from "@angular/material/core";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { Subject } from "rxjs";
@Component({
  selector: "app-forms-library",
  templateUrl: "./forms-library.component.html",
  styleUrls: ["./forms-library.component.scss"],
})
export class FormsLibraryComponent extends ApplicationInsightsBaseComponent {
  public lastKeyCode: number;
  public secretKeyCode = false;
  @ViewChild("dialogContent") dialogContent: TemplateRef<any>;
  @HostBinding("style.width") width: string;
  gridApi: any;
  public formsGridOptions: GridOptions;
  public policyFormList: RuleEngineForm[] = [];
  public allAdminForms: AdminForm[] = [];
  public packageNames: string[] = [];
  public currentPolicyForm: string;
  public currentPackage: string;
  lineOfBusinessList: ProductList[] = [];
  authRepresentative = new FormControl("");
  isDropdownOpen = false;
  public sortFields: SortObject;

  //Codes that indicate excess forms, only need excess for filter because if its not excess its primary, hardcoded because we cannot get this specific list of codes programatically
  excessCodes: any[] = ["118", "128", "130", "131", "298"];
  covTypeList: any[] = [
    { ID: "primary", Name: "Primary" },
    { ID: "excess", Name: "Excess" },
  ];

  //Lists for filtering and updating the grid based on selectors
  public selectedLOB: string;
  public selectedCovList: any[] = [];
  public selectedCovForms: any[] = [];
  public selectedCov: any;
  public packetFormList: RuleEngineForm[] = [];
  public stateRuleList: any[] = [];
  public paperRuleList: any[] = [];
  public allPaperRules: any[] = [];
  public bermudaPaperOnly: any[] = [
    { Code: "15", Description: "Markel Bermuda Limited" },
  ];
  public evanstonPaperOnly: any[] = [
    { Code: "4", Description: "Evanston Insurance Company" },
  ];
  public decPageList: any[] = [];
  public selectedState: string;
  public selectedStateForms: any[] = [];
  public selectedPaper: string;
  public selectedPaperForms: any[] = [];
  public selectedCovType: string;
  public selectedCovTypeForms: any[] = [];
  public selectedDecPage: string;
  public selectedDecPageForms: any[] = [];
  public primaryForms: any[] = [];
  public excessForms: any[] = [];
  public isMandatory: string = "All";
  lobQuery: string;
  checkedForms: string[] = [];
  public filter: string = "default";
  public searchInput: string;
  public selectedInput: string = "formnum";
  checkedFormsFulls: RuleEngineForm[] = [];
  public isMandatoryList = ["All", "Mandatory", "Optional"];
  public isInternationalCasulty: boolean;
  public isMINT: boolean;
  public isETaskLOB: boolean = false;
  public isExpanded: boolean = false;
  public currentTransaction: TransactionResponse;
  public producerName: string = "";
  public insuredDBA: string = "";
  public insuredName: string = "";
  public insuredAddress: string = "";
  public insuredAddress2: string = "";
  public insuredAddress3: string = "";
  public policyNumber: string = "";
  public policyEffectiveDate = "";
  public policyExpirationDate = "";
  public isExistingTransaction: boolean = false;
  public selectedTransactionId: string = "";
  public authorizedRepName: string = "";
  public authorizedRepSurname: string = "";
  public authorizedRepTitle: string = "";
  public dealNumber: string = "";
  public lobIsCpmV2: boolean = true;
  public authRepPlaceholder = "Click to Select";
  public issuanceLOB;
  boolean = false;
  //Hard coding underwriter list, no db location for this at the moment
  public underwriters: any[] = [
    { Name: "Michael", Surname: "Souza", Title: "Vice President" },
    { Name: "Chris", Surname: "Finsness", Title: "Underwriter" },
    { Name: "Jillian", Surname: "Dupree", Title: "Senior Underwriter" },
  ];
  public mintUnderwriters: any[] = [
    { Name: "Kyle", Surname: "Rogers", Title: "Senior Underwriter" },
    { Name: "Sarah", Surname: "Shriner", Title: "Associate Underwriter" },
    {
      Name: "Andrew",
      Surname: "Umphress",
      Title: "Head of Terrorism - North America",
    },
  ];
  public selectedUnderwriter: string = "";
  public selectedFormCount = 0;
  stateCtrl: UntypedFormControl;
  suggestedForms: Array<{ name: string }> = [];
  isSearchInputEmpty: boolean = true;
  private searchSubject: Subject<string> = new Subject<string>();
  /**
   * Constructor
   * @ignore
   */
  constructor(
    private route: ActivatedRoute,
    private _appInsightsService: AppInsightsService,
    private toastr: ToastrService,
    private formsService: FormsListService,
    private _modal: NgbModal,
    private sharedService: SharedService,
    private authService: AuthorizationService,
    private dateTimePipe: DateTimeUtcPipe,
    private userProfileService: UserProfileService,
    private dialog: MatDialog
  ) {
    super(_appInsightsService);
    this.width = "100%";
    this.searchSubject
      .pipe(
        debounceTime(300), 
        distinctUntilChanged(), 
        switchMap((input: string) => this.formsService.getAutoSuggestions(input))  
      )
      .subscribe((response) => {
        this.suggestedForms = response.Results.map((form) => ({
          name: form.AttributeSets[0]?.FormName || "Unknown",
        }));
      });
  }
  get FormsWritePermission(): boolean {
    return this.authService.FormsWrite();
  }

  //Angular Lifecycles
  /**
   * NgOnInit
   * @ignore
   */
  ngOnInit(): void {
    this.configureGrid();
  }

  /**
   * NgDestroy
   * @ignore
   */
  ngOnDestroy(): void {}

  onRowClicked(e) {
    // Check if the target clicked is the 'Internal Form Number' cell
    if (e.event.target && e.event.target.classList.contains("cursor-pointer")) {
      var formsdata = e.data; // Get the row data
      var isMol = formsdata.ImportFromMOL as boolean;
      let isDocX = formsdata.AttributeSets[0].FormName.includes("DOCX");
      // Trigger the download for the clicked form
      return this.downloadForm(
        `"${formsdata.InternalFormNumber}"`,
        false, // Do not preview by default, just download
        isDocX,
        isMol
      );
    }
  }

  //Private Methods
  /**
   * configure grid
   */
  private configureGrid(): void {
    this.formsGridOptions = <GridOptions>{
      rowSelection: "multiple",
      domLayout: "normal",
      floatingFilter: true,
      columnDefs: this.createColumDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      suppressRowClickSelection: false,
      paginationPageSize: 10,
      cacheBlockSize: 10, // Set the cache block size (matches your page size)
      maxBlocksInCache: 10, // Limits the cache size (you can tweak this if needed)
      enableColResize: true,
      suppressMenuHide: true,
      alwaysShowVerticalScroll: true,
      rowModelType: "infinite",
      datasource: this.createDataSource(), // Create the data source
      defaultColDef: {
        cellStyle: { textAlign: "left" },
      },
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent,
      },
      onGridReady: (params) => {
        this.formsGridOptions.columnApi = params.columnApi;
        this.formsGridOptions.api.sizeColumnsToFit();
        if (this.policyFormList.length > 0) {
          this.formsGridOptions.api.setRowData(this.policyFormList);
        } else {
          this.formsGridOptions.api.setRowData([]);
        }
        this.formsGridOptions.api = params.api;
      },
      onRowClicked: (event) => {
        this.onRowClicked(event);
      },
      onFilterChanged: (event) => {},
      onSortChanged(event) {},
      onFilterModified: function (event) {
        console.log(event);
      },
    };
  }

  private getSortFields(sortModel) {
    const sortedFields = sortModel
      .filter((sort) => sort.sort) // Only include sorted columns
      .map((sort) => ({
        column: sort.colId, // Column ID
        order: sort.sort === "asc" ? SortOrder.Ascending : SortOrder.Descending, // Sorting order
      }));
    return sortedFields.length > 0 ? sortedFields[0] : null;
  }
  private createDataSource() {
    return {
      getRows: (params) => {
        const pageSize = 10;
        const startRow = params.startRow; // Starting row index
        const endRow = params.endRow; // Ending row index
        const pageNumber = Math.floor(startRow / pageSize) + 1;
        const searchRequest: SearchRequest = {
          searchTerm: this.searchInput,
          pageNumber: pageNumber,
          pageSize: pageSize,
          sortField: this.getSortFields(params.sortModel),
          filters: [], // Populate filters as needed
        };
        // Call your service to fetch data
        this.formsService.getFilteredForms(searchRequest).subscribe(
          (response: SearchResponse) => {
            if (
              Array.isArray(response.Results) &&
              response.Results.length > 0
            ) {
              params.successCallback(response.Results, response.TotalCount); // Data passed with total count
            } else {
              params.successCallback([], 0); // No results
            }
          },
          (error) => {
            console.error("Error fetching forms:", error);
            params.failCallback(); // Handle errors
          }
        );
      },
    };
  }

  /**
   * Configure Columns
   */
  private createColumDef(): any[] {
    var isIC = this.authService.isIC();
    let result: any[] = [
      {
        headerName: "",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        field: "RowSelect",
        suppressMenu: true,
        resizable: true,
        suppressSorting: true,

        minWidth: 60,
        maxWidth: 70,
        filter: "booleanFilterComponent",
      },
      {
        headerName: "Internal Form Number",
        field: "InternalFormNumber",
        headerTooltip: "Internal Form Number",
        tooltipValueGetter: (params) => params.value,
        suppressMenu: true,
        sortable: true,

        minWidth: 260,
        maxWidth: 270,
        resizable: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        cellRenderer: (params) => {
          return `<span class="cursor-pointer">${params.value || " "}</span>`; // Fallback for undefined values
        },
        cellStyle: {
          color: "#007bff" /* Link color */,
          textDecoration:
            "underline" /* Underline text to make it look like a link */,
          cursor: "pointer" /* Change cursor to pointer on hover */,
        },
      },
      {
        headerName: "Form Name",
        field: "AttributeSets[0].FormName",
        sortable: true,
        headerTooltip: "Form Name",
        tooltipValueGetter: (params) => params.value,
        suppressMenu: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          suppressAndOrCondition: true,
        },
        minWidth: 370,
        maxWidth: 370,
        resizable: true,
        cellStyle: { "justify-content": "left", "white-space": "normal" },
        autoHeight: true,
        comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        cellRenderer: (params) => {
          // Check if AttributeSets exists and return the FormName
          return params.data?.AttributeSets &&
            params.data.AttributeSets[0]?.FormName
            ? params.data.AttributeSets[0].FormName
            : ""; // Return an empty string if not available
        },
      },
      {
        headerName: "Effective Date",
        headerTooltip: "Effective Date",
        tooltipValueGetter: (params) => params.value,
        field: "AttributeSets[0].EffectiveDate",
        suppressMenu: true,
        sortable: true,
        minWidth: 150,
        maxWidth: 150,
        resizable: true,
        filter: "dateFilterComponent",

        cellRenderer: (params) => {
          return params.data?.AttributeSets &&
            params.data.AttributeSets[0]?.["EffectiveDate"]
            ? this.dateTimePipe.transform(
                params.data.AttributeSets[0]["EffectiveDate"]
              )
            : ""; // Handle missing data
        },
      },
      {
        headerName: "Expiration Date",
        field: "AttributeSets[0].ExpirationDate",
        suppressMenu: true,
        sortable: true,
        headerTooltip: "Expiration Date",
        tooltipValueGetter: (params) => params.value,
        minWidth: 160,
        maxWidth: 160,
        resizable: true,
        filter: "dateFilterComponent",
        cellRenderer: (params) => {
          return params.data?.AttributeSets &&
            params.data.AttributeSets[0]?.ExpirationDate
            ? this.dateTimePipe.transform(
                params.data.AttributeSets[0].ExpirationDate
              )
            : ""; // Handle missing data
        },
      },
      {
        headerName: "Submission Exp Date",
        field: "AttributeSets[0].SubmissionExpirationDate",
        suppressMenu: true,
        sortable: true,
        minWidth: 200,
        maxWidth: 200,
        headerTooltip: "Submission Exp Date",
        tooltipValueGetter: (params) => params.value,
        resizable: true,
        filter: "dateFilterComponent",
        hide: isIC,
        cellRenderer: (params) => {
          return params.data?.AttributeSets &&
            params.data.AttributeSets[0]?.SubmissionExpirationDate
            ? this.dateTimePipe.transform(
                params.data.AttributeSets[0].SubmissionExpirationDate
              )
            : ""; // Handle missing data
        },
      },
      {
        headerName: "Form Type",
        field: "AttributeSets[0].FormType",
        headerTooltip: "Form Type",
        tooltipValueGetter: (params) => params.value,
        sortable: true,
        minWidth: 150,
        maxWidth: 150,
        resizable: true,
        filter: "agTextColumnFilter",
        suppressMenu: true,
        cellRenderer: (params) => {
          // Check if AttributeSets exists and return the FormName
          return params.data?.AttributeSets &&
            params.data.AttributeSets[0]?.FormType
            ? params.data.AttributeSets[0].FormType
            : ""; // Return an empty string if not available
        },
      },
    ];

    return result;
  }

  //Download a form or list of forms, done by taking raw return data and converting into blob with type pdf
  // if isPreview open the form in a new tab instead
  private downloadForm(
    policyForms: string,
    isPreview: boolean,
    isDocX: boolean,
    isMol: boolean,
    adminForms?: RuleEngineForm[]
  ) {
    console.log(isMol);
    if (isMol) {
      this.formsService.getAllMolForms(adminForms).subscribe((res) => {
        console.log(Object.values(res));
        var response = Object.values(res)[0] as string;

        const byteCharacters = atob(response);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        let blob: Blob;
        blob = new Blob([byteArray], {
          type:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        //window.open(res);
        let url = URL.createObjectURL(blob);
        window.open(url);
      });
    } else {
      this.formsService.getJwt().subscribe((t) => {
        console.log(t);
        this.formsService
          .downloadSpecimen(policyForms, t, isDocX, isMol)
          .subscribe(
            (res) => {
              const byteCharacters = atob(res[0].Content);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              let blob: Blob;
              if (isDocX) {
                blob = new Blob([byteArray], {
                  type:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
              } else {
                blob = new Blob([byteArray], { type: "application/pdf" });
              }
              //Need logic specifically for IE because its a special little boy that refuses to handle blob files
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                if (!isPreview) {
                  window.navigator.msSaveOrOpenBlob(
                    blob,
                    "Ghostportal_Form.pdf"
                  );
                } else {
                  window.navigator.msSaveOrOpenBlob(
                    blob,
                    "Ghostportal_Form.pdf"
                  );
                }
              } else {
                var downloadURL = window.URL.createObjectURL(blob);
                if (isPreview) {
                  window.open(downloadURL, "_blank");
                } else {
                  var link = document.createElement("a");
                  link.href = downloadURL;
                  link.download = "GhostPortal_Form.pdf";
                  link.click();
                }
              }
            },
            (err) => {
              console.log(err);
              this.toastr.error(err.error, "Ghostdraft Generation Error");
            }
          );
      });
    }
  }

  onSearchKey(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.externalFilterChanged(event?.target?.["value"] ?? "", "search");
    }
  }

  public currentPage: number = 1;

  externalFilterChanged(selectedValue: string, filter: string) {
    this.filter = filter;
    if (this.formsGridOptions?.api) {
      switch (filter) {
        case "search":
          this.currentPage = 1;
          this.formsGridOptions.api.paginationGoToPage(0);
          this.formsGridOptions.api.onFilterChanged();
          this.formsGridOptions.api.onFilterChanged(); // Trigger refresh after filter change
          break;
        // Add other cases for other filters if needed
      }
    } else {
      console.error("Forms Grid API is not available");
    }
  }

  resetSearch() {
    if (this.formsGridOptions?.api) {
      this.searchInput = ""; // Clear input
      this.formsGridOptions.api.setFilterModel(null); // Remove all filters
      this.formsGridOptions.api.paginationGoToPage(0); // Go to the first page
      this.formsGridOptions.api.onFilterChanged(); // Trigger grid refresh
      this.formsGridOptions.api.setFilterModel(null); // Remove all filters
    } else {
      console.error("Forms Grid API is not available");
    }
  }

  getSuggestions(event: KeyboardEvent) {
    const input = (event.target as HTMLInputElement)?.value ?? "";

    if (input.length >= 3) {
      this.searchSubject.next(input); 
    } else {
      this.suggestedForms = []; 
    }
  }

  selectSuggestion(suggestion: { name: string }) {
    if (suggestion?.name) {
      this.searchInput = suggestion.name;
      this.suggestedForms = [];
      this.externalFilterChanged(this.searchInput, "search");
    } else {
      console.error("Selected suggestion is invalid:", suggestion);
    }
  }

  onOptionSelected(option: MatOption) {
    const selectedData = option?.value; // Check if 'value' exists on 'option'
    if (selectedData?.name) {
      this.searchInput = `${selectedData.name}`;
      this.suggestedForms = [];
      this.externalFilterChanged(this.searchInput, "search");
    } else {
      console.error("Selected data is incomplete or missing:", selectedData);
    }
  }
}
