
import { map } from 'rxjs/operators';
//Angular Imports 
import { HttpClient, HttpContext, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

//Third Party Imports
import { Observable, Subscriber } from "rxjs";
import { PolicyForm, ExtensionRequest, Product, CPMForm, CPMElement, TransactionResponse } from "../models";
import { AdminForm } from "../../../form-page";
import { RuleEngineForm } from "../../../form-page/models/ruleEngineForm.model";
import { AuthorizationService, EnvironmentService } from "../../../framework/services";
import { User } from "msal";
import { CPMUploadRequest } from '../models/cpmUploadRequest';
import { ETaskRequest } from '../models/etask.request.model';
import { OrchAction, OrchRequest } from '../../../framework/models/orch-request-body.model';
import { BYPASS_URL_APPEND } from '../../../framework/interceptors/http-request.interceptor';
import { formatDate } from '@angular/common';
import { List } from 'lodash';
import { SearchRequest } from '../models/search-request.model';
import { SearchResponse } from '../models/search-response.model';
/**
  * Policy Forms Provider
*/
@Injectable()
export class FormsListService {

  /**
   * Constructor
   * @ignore
  */
  constructor(private http: HttpClient,
    private environmentService: EnvironmentService,
    private authService: AuthorizationService) {
  }

  downloadSpecimen(formName: string, token: string, isDocX: boolean, isMol: boolean): Observable<any> {

    var previewData = `<PolicyMessage xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xmlns:xsd=\"http://www.w3.org/2001/XMLSchema\" xmlns=\"http://markel.common.policy/2017/1\">
                                                        <MessageID>ED049355-0FBD-43C5-84C5-53CC72D0DA25</MessageID>
                                                        <SourceSystem>38</SourceSystem>
                                                        <SourceSystemName>e2</SourceSystemName>
                                                        <CallingSystem>38</CallingSystem>
                                                        <CallingSystemName>e2</CallingSystemName>
                                                        <TargetSystem>GhostDraft</TargetSystem>
                                                        <TargetSystemName>GhostDraft</TargetSystemName>
                                                        <ActionType>PrepareQuoteDocuments</ActionType>
                                                        <ActionReason xsi:nil=\"true\" />
                                                        <MessageDate>2019-06-20T06:37:27.2870331</MessageDate>
                                                        <ActionEffectiveDate>2019-06-20T00:00:00</ActionEffectiveDate>
                                                        <TransactionPremiumAmount>150937.5</TransactionPremiumAmount>
                                                        <Policy>
                                                        </Policy>
                                                      </PolicyMessage>`

    previewData = previewData.replace(/"/g, '\\"');
    var environment = this.environmentService.environmentDetails.Environment.toLowerCase();
    if (environment == 'local') {
      environment = 'DEV2';
    }
    var gurl = `https://mkl-mgdapi-${environment.toLowerCase()}.azurewebsites.net/api/v2/document/preview`;
    var headers = new HttpHeaders();

    headers = headers.append('Authorization', `Bearer ${token}`);
    headers = headers.append('Content-Type', 'application/json');
    var data = "";
    if (isMol) {
      this.getAllMolForms;
    }else
    if (isDocX) {
      headers = headers.append('Accept', '*/*')
      data = `{"Documents":[${formName}],"Data":"${previewData}","CallingSystem":"GhostPortal","Environment":"${environment}","TransactionId":null,"DocumentDomain":null,"WithData":false,"IsDraft":true,"CombineDocuments":false, "DocumentFormat":"DocX"}`;
    }
    else {
      headers = headers.append('Accept', 'application/pdf');
      data = `{"Documents":[${formName}],"Data":"${previewData}","CallingSystem":"GhostPortal","Environment":"${environment}","TransactionId":null,"DocumentDomain":null,"WithData":false,"IsDraft":true}`;
    }
  
    return this.http.post<any>(gurl, data, { headers }).pipe(map(res => res));
  }
  getRuleTypeDetail(lobName: string): Observable<PolicyForm[]> {
    let url = `api/FormsMaster?lob=` + lobName;
    return this.http.get<PolicyForm[]>(url).pipe(
      map(res => res)
    );
  }

  getJwt() {
    var gurl = `https://mkl-mgdapi-prod.azurewebsites.net/api/v2/document/login?UserName=Specialty&Key=BD5065AF-2A62-4002-B08F-0EB91221FDF1`;
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    return this.http.get(gurl, { headers, responseType: 'text' }).pipe(map(res => res));
  }

  getExtensionJWT() {
    var gurl = `${this.environmentService.environmentDetails.GDX_ORIGIN}/api/login`;
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    if (this.environmentService.environmentDetails.Environment == 'PROD') {
      headers =headers.append('Authorization', `Basic ${btoa('DocPortal:52f469C8-6C95-410C-B30E-5124EDF78CA6')}`)
    }
    else {
      headers =headers.append('Authorization', `Basic ${btoa('DocPortal:DD5065AF-2A62-4002-B08F-0EB91221FDA7')}`);
    }
    console.log(headers);
    return this.http.get(gurl, { headers, responseType: 'text' }).pipe(map(res => res));
  }

  getRuleEngineFormsForLOB(lob: string): Observable<RuleEngineForm[]> {
    let url = `api/AdminForm/RuleEngineForms/` + lob;
    return this.http.get<RuleEngineForm[]>(url).pipe(
      map(res => res));
  }

  getAdminFormsForLOB(lobName: string): Observable<AdminForm[]> {
    let url = `api/AdminForm/Lob/` + lobName;
    return this.http.get<AdminForm[]>(url).pipe(
      map(res => res)
    );
  }

  getFilteredForms(searchRequest: SearchRequest): Observable<SearchResponse> {
    const url = 'api/AdminForm/Search';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<SearchResponse>(url, searchRequest, { headers }).pipe(
      map(response => response)
    );
  }
 
  getAutoSuggestions(searchTerm: string): Observable<SearchResponse> {
    const searchRequest: SearchRequest = {
        searchTerm: searchTerm,
        pageNumber: 1, 
        pageSize: 10, 
        filters: [], 
        sortField: null 
    };
    return this.getFilteredForms(searchRequest).pipe(
        map(response => response) 
    );
}
  renewTransaction(transId: string, cpmElements: CPMElement[], cpmForms: CPMForm[], token: string) {
    var uploadRequest: CPMUploadRequest = new CPMUploadRequest();
    uploadRequest.cpmElements = cpmElements;
    uploadRequest.renewalForms = cpmForms;
    console.log(uploadRequest);
    let url = `${this.environmentService.environmentDetails.GDX_ORIGIN}/api/renew?transactionId=${transId}`;
    var headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.post<string>(url, uploadRequest, { headers });
  }

  createExtensionTransaction(forms: CPMForm[], cpm: string, transId: any, cpmElements: CPMElement[], username: string, token: string, dealNumber: string, lob:string): Observable<any> {
    let req: ExtensionRequest = new ExtensionRequest();
    req.CPMXml = cpm;
    let product: Product = new Product();
    product.LineOfBusinessID = "1564"; // change this
    product.ProductName = "Policy Forms";
    let env = this.environmentService.environmentDetails.Environment;
    if (lob == "INTC") {
      product.ProductName = "International Casualty";
    }
    else if (lob == "MIN") {
      product.ProductName = "Markel U.S. Terrorism";
    }
    let products: Product[] = [];
    products.push(product);
    req.Products = products;
    req.CallBackUrl = `https://localhost:44392/api/issuance/ic/queue?dealNumber=${dealNumber}&location=Bermuda&environment=${env}`;
    req.UserName = username;
    req.CPMForms = forms;
    req.CPMElements = cpmElements;
    let json = JSON.stringify(req);
    let url = `${this.environmentService.environmentDetails.GDX_ORIGIN}/api/upload-cpmxml?transactionId=${transId}`;
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    return this.http.post(url, json, { headers, responseType: 'text' }).pipe(map(res => res));
  }

  getBaseCpm(isV2: boolean): Observable<string> {
    let cpmFileName = "";
    if (isV2) cpmFileName = "BaseCPMv2.xml"; else cpmFileName = "BaseCPM.xml";
    return this.http.get(`assets/files/${cpmFileName}`, { responseType: 'text' }).pipe(map(res => res));
  }

  getTransactionsByUserName(username: string): Observable<TransactionResponse[]>{
    let url = `${this.environmentService.environmentDetails.GDX_ORIGIN}/api/transaction/fetch/${username}`;
    return this.http.get<TransactionResponse[]>(url).pipe(map(res => res));
  }

  getAllTransactions(lob: string): Observable<TransactionResponse[]> {
    let url = `${this.environmentService.environmentDetails.GDX_ORIGIN}/api/transaction/fetch/all?includeIssued=true&product=${lob}`;
    return this.http.get<TransactionResponse[]>(url).pipe(map(res => res));
  }

  regeneratePolicy(transaction: string): Observable<any> {
    transaction = transaction.toUpperCase();
    let url = `${this.environmentService.environmentDetails.GDX_ORIGIN}/api/policy-pdf/${transaction}`;
    var headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf');
    return this.http.get(url, { headers, responseType:'blob' }).pipe(map(res => res));
  }

  deleteTransactionData(transactionId: string): Observable<any> {
    let url = `${this.environmentService.environmentDetails.GDX_ORIGIN}/api/transaction/delete/${transactionId}`;
    return this.http.delete<any>(url).pipe(map(res => res));
  }

  getAllAdminForms(): Observable<AdminForm[]> {
    let url = `api/AdminForm/AllForms`;
    return this.http.get<AdminForm[]>(url).pipe(
      map(res => res));
  }

  getProducts(): Observable<any[]> {
    let url = `api/Configs/Products/GetProducts`;
    return this.http.get<any[]>(url).pipe(
      map(res => res));
  }

  getAllMolForms(adminForms?: RuleEngineForm[]): Observable<any> {
    let url = 'api/molforms/preview';
    return this.http.post<any>(url, adminForms).pipe(map(res => res));
  }

  sendETask(etask: ETaskRequest): Observable<any> {
    var orchRequest = new OrchRequest();
    orchRequest.messageID = crypto.randomUUID();
    orchRequest.originatingSystem = "Insight Hub";
    orchRequest.messageDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    orchRequest.product = etask.LineOfBusiness;
    orchRequest.multipleInstructions = false;
    let orchAction = new OrchAction();
    orchAction.actionInstructionType = "etask";
    orchAction.InstructionSubtype = "CreateTask";
    orchAction.environment = "UAT1";// this.environmentService.environmentDetails.Environment;
    orchRequest.action.push(orchAction);
    orchRequest.etask = etask;
    let url = 'https://mkl-doc-orch-nonprod.azurewebsites.net:443/api/orchestrator/triggers/orchestrate/invoke?api-version=2022-05-01&sp=%2Ftriggers%2Forchestrate%2Frun&sv=1.0&sig=x_JZEvB6khshQZEiUGpBWlsCD_JSGP6Y_TprpTYiElg';
    return this.http.post<any>(url, orchRequest, { context: new HttpContext().set(BYPASS_URL_APPEND, true) }).pipe(map(res => res));
  }
}
