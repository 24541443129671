import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { UserProfileService, UserProfile, EnvironmentService, SharedService } from '../../../framework';


@Component({
  selector: 'app-mdocument-layout',
  templateUrl: './mdocument-layout.component.html',
  styleUrls: ['./mdocument-layout.component.scss']
})
export class MdocumentLayoutComponent implements OnInit {

  public userProfile: UserProfile;

  constructor(public userProfileService: UserProfileService, public environmentService: EnvironmentService, public sharedService: SharedService, private router: Router) {
    this.userProfile = userProfileService.UserProfile;
  }

  ngOnInit(): void {
    /*
     Hide the preloader div on the index.html pages on init. If you don't do this, the UI of the home component
     will be hidden on page reload / hot reload
    */

    const appInsights = new ApplicationInsights({
      config: {
        connectionString: window.sessionStorage.getItem("AIKey")
        /* ...Other Configuration Options... */
      }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView({
      name: 'mdocument/main',
      uri: location.pathname + location.search
    });

    this.sharedService.hidePreloader();
  }

  returnToMainMenu(event: any) {
    event.preventDefault();
    this.router.navigateByUrl('/');
  }

}
