//Angular Imports
import { Component, HostBinding, OnInit, ViewChild } from "@angular/core";

//Third Party Imports
import { GridOptions, IDatasource, IGetRowsParams } from "ag-grid-community";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";

//Internal Imports

import "rxjs";
import { saveAs } from "file-saver";
import { filter, forkJoin, map, startWith } from "rxjs";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import {
  AppInsightsService,
  ReportService,
  EnvironmentService,
  DateTimeUtcPipe,
  SharedService,
  ApplicationInsightsBaseComponent,
  CPMElement,
  UserProfileService,
  UserProfile,
  DateFilterComponent,
  BooleanFilterComponent,
} from "../../../framework";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MigrationService } from "../services/migration.service";
import { MigrationLogs, MigrationSummaryLogs } from "../../models/migrationLogs";
import { TestCaseError } from "../../testSuites/testHarness/components/testCaseError/testCaseError.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";


export class FileModel {
  FileNumber: number;
  FileExists: boolean;
  FileID: string | null;
  FileName: string | null;

  constructor(fileNumber: number, fileExists: boolean, fileID: string | null, fileName: string | null) {
    this.FileNumber = fileNumber;
    this.FileExists = fileExists;
    this.FileID = fileID ? fileID.toString() : null;
    this.FileName = fileName;
  }
}

export class DocFileModel {
  FileNumber: number;
  DocumentExists: boolean;
  DocumentID: string | null;
  DocumentName: string | null;
  DocumentFileName: string | null;

  constructor(fileNumber: number, docExists: boolean, docID: string | null, docName: string | null, docFileName: string | null) {
    this.FileNumber = fileNumber;
    this.DocumentExists = docExists;
    this.DocumentID = docID ? docID.toString() : null;
    this.DocumentName = docName;
    this.DocumentFileName = docFileName;
  }
}
/**
 * Forms Component
 */
@Component({
  selector: "app-document-migration",
  templateUrl: "./migration.component.html",
  styleUrls: ["./migration.component.scss"],
  providers: [],
})
export class MigrationComponent implements OnInit {



  public userProfile: UserProfile;

  public migrationLogs: MigrationLogs = new MigrationLogs();

  public displayList: boolean = false;

  public migrationErrorGridOptions: GridOptions;

  public resultsGridOptions: GridOptions;

  public docResultsGridOptions: GridOptions;

  public validateDocs: boolean = false;

  public continuationToken: string = null;

  parsedData: FileModel[] = [];

  parsedDocData: DocFileModel[] = [];


  constructor(
    public userProfileService: UserProfileService,
    public sharedService: SharedService,
    public migrationService: MigrationService,
    private router: Router,
    private route: ActivatedRoute,
    private _modal: NgbModal
  ) {
    this.userProfile = userProfileService.UserProfile;
  }

  ngOnInit(): void {

    const appInsights = new ApplicationInsights({
      config: {
        connectionString: window.sessionStorage.getItem("AIKey")
        /* ...Other Configuration Options... */
      }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView({
      name: 'migration/main',
      uri: location.pathname + location.search
    });
    /*
     Hide the preloader div on the index.html pages on init. If you don't do this, the UI of the home component
     will be hidden on page reload / hot reload
    */
    this.sharedService.hidePreloader();
    this.configureGrid();
    this.configureResultsGrid();
    //this.configureResultsGrid();
   /* this.migrationService.getMigrationLogs().subscribe(res => {
      this.migrationLogs = new MigrationLogs();
      for (var i = 0; i < res.summaryLogs.length; i++) {
        var tempSummaryLog = new MigrationSummaryLogs();
        tempSummaryLog.FilesUploaded = res.summaryLogs[i]['Files Successfully Uploaded'];
        tempSummaryLog.FilesFailed = res.summaryLogs[i]['Files Unsuccessfully Uploaded'];
        tempSummaryLog.TimeToComplete = res.summaryLogs[i]['Time To Complete'];
        tempSummaryLog.TimeToComplete = parseFloat(tempSummaryLog.TimeToComplete.toFixed(2));
        console.log(tempSummaryLog);
        this.migrationLogs.summaryLogs.push(tempSummaryLog);
      }
      this.migrationLogs.errorLogs = res.errorLogs;
      console.log(this.migrationLogs.errorLogs);
      this.displayList = true;

      this.migrationErrorGridOptions.api.setRowData(this.migrationLogs.errorLogs);
    })*/

    

  }

  onTabChange(event: MatTabChangeEvent) {
    console.log(event);
    if (event.tab.textLabel == "Validation") {
      
    }
  }

  //Fix malformed JSON's in logs
  wrapValuesInQuotes(jsonString:string):string {
    var FileNameIndex = jsonString.indexOf("FileName\":") + 10;
    if (FileNameIndex > -1) {
      jsonString = jsonString.slice(0, FileNameIndex) + '"' + jsonString.slice(FileNameIndex);
      var lastCurlyBrace = jsonString.lastIndexOf("}")-1;
      jsonString = jsonString.slice(0, lastCurlyBrace) + '"' + jsonString.slice(lastCurlyBrace);
    }
    var FileNumberIndex = jsonString.indexOf("FileNumber\":") + 12;
    if (FileNumberIndex > -1) {
      jsonString = jsonString.slice(0, FileNumberIndex) + '"' + jsonString.slice(FileNumberIndex);
      var firstComma = jsonString.indexOf(",");
      jsonString = jsonString.slice(0, firstComma) + '"' + jsonString.slice(firstComma);
    }
    return jsonString;
  }

  parseJsonData(jsonData: string[]): void {
    jsonData.forEach(data => {
      const processedData = this.wrapValuesInQuotes(data);
      console.log(processedData);
      const parsed = JSON.parse(processedData);
      const fileModel = new FileModel(parsed.FileNumber, parsed.FileExists, parsed.FileID, parsed.FileName);
      this.parsedData.push(fileModel);
    });
    console.log(this.parsedData);
    //this.resultsGridOptions.api.setRowData(this.parsedData);
  }

  parseJsonDataSingle(jsonData: string): FileModel {
      const processedData = this.wrapValuesInQuotes(jsonData);
      console.log(processedData);
      const parsed = JSON.parse(processedData);
      const fileModel = new FileModel(parsed.FileNumber, parsed.FileExists, parsed.FileID, parsed.FileName);
      return fileModel;
  }

  private configureGrid(): void {
    this.migrationErrorGridOptions = <GridOptions>{
      rowSelection: "single",
      domLayout: "autoHeight",
      columnDefs: this.createColDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 8,
      rowHeight: 50,
      enableColResize: true,
      context: { componentParent: this },
      frameworkComponents: {
        dateFilterComponent: DateFilterComponent,
        booleanFilterComponent: BooleanFilterComponent
      },
      onGridReady: () => {
        this.migrationErrorGridOptions.api.setRowData([]);
        this.migrationErrorGridOptions.api.sizeColumnsToFit();
      },
      onRowClicked: (event) => {
        this.onRowClick(event);
      },
      onFilterChanged: (event) => { },
      onSortChanged: (event) => { },
      onFilterModified: function (event) {
        //console.log(event);
      },
    };
  }

  private createColDef(): any[] {
    let columns = [
      {
        headerName: "Item Number",
        field: "itemnum",
        filter: "agTextColumnFilter",
        width:250
      },
      {
        headerName: "Document",
        field: "filename",
        filter: "agTextColumnFilter",
        width:250
      },
      { headerName: "Item Name", field: "itemname", filter: "agTextColumnFilter", flex: 1 },
      {
        headerName: "Actions",
        width: 70,
        flex: 1,
        suppressMenu: true,
        suppressSorting: true,
        filter: 'booleanFilterComponent',
        cellRenderer: params => {
          return `<img src="/assets/images/preview_icon.png" data-action-type="Preview" class="cursor_pointer" title="Preview">`;
          
        }
      }
    ];

    return columns;
  }

  private onRowClick(e: any): void {
    if (e.event.target !== undefined) {
      let data = e.data;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "Preview":
          const modalRef = this._modal.open(TestCaseError, { windowClass: 'xl' });
          modalRef.componentInstance.errorText = data.errorMessage;
          break;
      }
    }
  }

  private configureResultsGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.resultsGridOptions = <GridOptions>{
      overlayLoadingTemplate:
        '<div class="loadingx" style="margin: 7em"><span class="ag-overlay-loading-center " style="font-size: 18px; z-index: 100000"> Loading Logs ...</span></div> ',
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createResultsColumnDef(),
      enableFilter: true,
      enableSorting: true,
      rowModelType: 'infinite',
      cacheBlockSize: 1000,
      maxBlocksInCache: 10,
      infiniteInitialRowCount: 1,
      datasource: this.createDataSource(),
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.resultsGridOptions.api.setRowData([]);
        //this.resultsGridOptions.api.setRowData(this.allResults);
        this.resultsGridOptions.api.sizeColumnsToFit();
        //this.resultsGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
       // this.onResultsRowClick(event);
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        console.log(event);
      }
    };
  }

  createResultsColumnDef():any[] {
    return [
      { headerName: 'File Number', field: 'FileNumber', resizable: true, width: 200, filter: 'agTextColumnFilter', suppressSizeToFit: false, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'File Exists', field: 'FileExists', resizable: true, width: 150, filter: 'agTextColumnFilter', suppressSizeToFit: false, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'File ID', field: 'FileID', resizable: true, width: 200, filter: 'agTextColumnFilter', suppressSizeToFit: false, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'File Name', field: 'FileName', resizable: true, width: 800, filter: 'agTextColumnFilter', suppressSizeToFit: false, comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
    ]
  }

  createDataSource(): IDatasource {
    return {
      getRows: (params: IGetRowsParams) => {
        const pageSize = params.endRow - params.startRow;
        this.migrationService.getValidationLogsAsPages(pageSize, this.continuationToken).subscribe(response => {
          this.continuationToken = response.ContinuationToken;
          const rowsThisPage = response.Results.map((log: string) => {

            const processedData = this.parseJsonDataSingle(log);
            return processedData;
          });
          this.continuationToken = response.ContinuationToken;
          const lastRow = this.continuationToken ? null : params.startRow + rowsThisPage.length;
          params.successCallback(rowsThisPage, lastRow);
        });
      }
    };
  }

  getDocValidation() {
    this.validateDocs = true;
    this.configureDocResultsGrid();
    this.migrationService.getValidationLogsDocs().subscribe(res => {
      this.parseDocJsonData(res);
    })
  }

  parseDocJsonData(jsonData: string[]): void {
    jsonData.forEach(data => {
      const processedData = data;
      console.log(processedData);
      const parsed = JSON.parse(processedData);
      const fileModel = new DocFileModel(parsed.FileNumber, parsed.DocumentExists, parsed.DocumentID, parsed.DocumentName, parsed.DocumentFileName);
      this.parsedDocData.push(fileModel);
    });
    console.log(this.parsedData);
    this.docResultsGridOptions.api.setRowData(this.parsedDocData);
  }

  exportData() {
    if(confirm('This will take a while, continue?'))
    this.migrationService.getCSVReport().subscribe(res => {

    })
  }

  private configureDocResultsGrid(): void {
    //this.initialRowDataLoad$ = [];
    this.docResultsGridOptions = <GridOptions>{
      overlayLoadingTemplate:
        '<div class="loadingx" style="margin: 7em"><span class="ag-overlay-loading-center " style="font-size: 18px; z-index: 100000"> Loading Logs ...</span></div> ',
      rowSelection: "single",
      domLayout: 'normal',
      columnDefs: this.createDocResultsColumnDef(),
      enableFilter: true,
      enableSorting: true,
      pagination: true,
      paginationPageSize: 20,
      rowHeight: 30,
      enableColResize: true,
      onGridReady: () => {
        this.docResultsGridOptions.api.setRowData([]);
        //this.resultsGridOptions.api.setRowData(this.allResults);
        this.docResultsGridOptions.api.sizeColumnsToFit();
        // this.resultsGridOptions.columnApi.autoSizeAllColumns();
      },
      onRowClicked: (event) => {
        // this.onResultsRowClick(event);
      },
      onFilterChanged: (event) => {
      },
      onSortChanged: (event) => {
      },
      onFilterModified: function (event) {
        console.log(event);
      }
    };
  }

  createDocResultsColumnDef(): any[] {
    return [
      { headerName: 'File Number', field: 'FileNumber', resizable:true, filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Document Exists', field: 'DocumentExists', resizable: true, filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Document ID', field: 'DocumentID', resizable: true, filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Document Name', field: 'DocumentName', resizable: true, filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
      { headerName: 'Doc File Name', field: 'DocumentFileName', resizable: true, filter: 'agTextColumnFilter', comparator: function (valueA, valueB, nodeA, nodeB, isInverted) { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); } },
    ]
  }

  downloadReport(): any {
    this.migrationService.downloadReport().subscribe(res => {
      const data: Blob = new Blob([res], {
        type: "text/csv;charset=utf-8"
      });
      console.log(res);
      // you may improve this code to customize the name 
      // of the export based on date or some other factors
      saveAs(data, "report.csv");
    });
  }

}
